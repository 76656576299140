import HTMLEditor from 'components/controls/WysiwygEditor';
import UploadFile from 'components/form/UploadFile';
import HelpIcon from 'components/icons/HelpIcon';
import { EPD_DEVELOPMENT_VERIFICATION_TEMPLATES_URL, EPD_LCA_TEMPLATES_URL } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import arrowDown from 'images/arrow-down.png';
import arrowUp from 'images/arrow-up.png';
import helpIcon from 'images/icons/svg/info.svg';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { MultiValue, SingleValue } from 'react-select';
import { EpdLinks } from 'routes/EpdRoutes';
import CollectionService from 'services/CollectionService';
import CpcService from 'services/CpcService';
import DataSourceVersionsService from 'services/DataSourceVersionsService';
import DataSourcesService from 'services/DataSourcesService';
import {
  ActionState,
  CollectionModel,
  DataSourceModel,
  DataSourceVersionModel,
  EPDModel,
  FlowPropertyModel,
  GeographicalScopeModel,
  LanguageModel,
  MaterialPropertyModel,
  Subtype,
  UploadDocumentTypesModel,
} from 'services/EpdClient';
import EpdService from 'services/EpdService';
import EPDService from 'services/EpdService';
import FlowPropertiesService from 'services/FlowPropertiesService';
import LanguagesService from 'services/LanguagesService';
import MaterialPropertiesService from 'services/MaterialPropertiesService';
import UploadDocumentTypesService from 'services/UploadDocumentTypesService';
import styled, { css } from 'styled-components';
import { useTheme } from 'styled-components';
import { A, ButtonSmall, H3, InfoBox, Label } from 'styles/Styles.styled';
import { Theme } from 'styles/theme';
import { Option } from 'types/types';

import { customToolbarEpd } from '../../components/form/customToolbar';
import { ImagesUpload } from './ImagesUpload';
import { Validation } from './Validation';
import { FixedLabel, FixedLabelTop, FixedTextArea, FixedTextInput, SelectWrapper } from './styles';
import { WizardStepValidationSchema } from './types';
import { useReferencePackageVersions15804 } from 'services/api/queries';

type SubtypeOption = {
  label: string;
  value: Subtype | undefined;
};

const WizardStep2: React.FunctionComponent<{
  epd?: EPDModel;
  onChangeEpd?: any;
  validation: WizardStepValidationSchema;
  isFetching: boolean;
}> = ({ epd, onChangeEpd, validation, isFetching }) => {
  const { company } = useContext(CompanyContext);
  const theme = useTheme() as Theme;
  const [collectionList, setCollectionList] = React.useState<CollectionModel[]>([]);
  const [locationList, setLocationList] = React.useState<Option[]>([]);
  const [lookupUNCPCCodes, setLookupUNCPCCodes] = useState<Option[]>([]);
  const [dataSources, setDataSources] = React.useState<DataSourceModel[]>([]);
  const [dataSourceVersions, setDataSourceVersions] = React.useState<DataSourceVersionModel[]>([]);
  const referencePackageVersions15804 = useReferencePackageVersions15804().data;
  const [languageList, setLanguages] = React.useState<LanguageModel[]>([]);
  const [documentTypeList, setDocumentTypes] = React.useState<UploadDocumentTypesModel[]>([]);
  const [materialProperties, setMaterialProperties] = React.useState<MaterialPropertyModel[]>([]);
  const [flowProperties, setFlowProperties] = React.useState<FlowPropertyModel[]>([]);
  const [flowPropertyOption, setFlowPropertyOption] = React.useState<{
    value: string | undefined;
    label: string | undefined;
  }>({ value: undefined, label: undefined });
  const [rowValues, setRowValues] = React.useState<any[]>([]);
  const [rowDataSourceValues, setDataSourceRowValues] = React.useState<any[]>([]);
  const [infoArrowItems, setInfoArrowItems] = useState<any>({
    gtin: false,
    geo: false,
    referenceFlows: false,
    technology: false,
    technicalPurpose: false,
    subType: false,
    materialProperties: false,
    dataSources: false,
    useForDatasets: false,
  });

  const [selectedFlowPropertyId, setSelectedFlowPropertyId] = useState<string | undefined>(
    epd?.productFlow?.flowPropertyItem?.flowPropertyId
  );
  const [selectedConversionFactor, setSelectedConversionFactor] = useState<number | undefined>(
    epd?.productFlow?.flowPropertyItem?.conversionFactor
  );

  const { t } = useTranslation();

  const getSubTypeOptionLabel = (key: string) => {
    switch (key) {
      case Subtype.Specific:
        return 'Specific (single product)';
      case Subtype.Average:
        return 'Average (multiple products)';
      case Subtype.Representative:
        return 'Representative (Sector EPD)';
      case Subtype.Template:
        return 'Template (product not yet on the market)';
      default:
        return '';
    }
  };

  const getSubTypeOptionDisabled = (key: string) => {
    switch (key) {
      case Subtype.Representative:
        return !epd?.isSectorEPD;
      default:
        return false;
    }
  };

  let subtypeOptions: any[] = [];
  for (let s in Subtype) {
    subtypeOptions.push({ value: s, label: getSubTypeOptionLabel(s), isDisabled: getSubTypeOptionDisabled(s) });
  }

  const hiddenEditorStyle = {
    border: '1px solid grey',
    padding: '0 1rem',
    fontSize: '14px',
    width: '100%',
    height: '20vh',
    background: theme.colors.lightGray,
  };
  const visibleEditorStyle = { border: '1px solid grey', padding: '0 1rem', fontSize: '14px', height: '20vh' };
  const productFlowInput = {
    margin: 0,
    borderRadius: 0,
    borderColor: 'gray',
    borderWidth: '1px',
    paddingLeft: '8px',
    boxSizing: 'border-box',
    width: '100%',
    height: '49px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'no-wrap',
    '-ms-text-overflow': 'ellipsis',
    '-o-text-overflow': 'ellipsis',
  };

  const toggleOpen = (item: any) => {
    const oldValue = infoArrowItems[item];
    const newObj = { ...infoArrowItems, [item as any]: !oldValue };
    setInfoArrowItems(newObj);
  };

  React.useEffect(() => {
    const fetchCollections = async () => {
      try {
        const result: any = await CollectionService.getCollections(company?.id);
        setCollectionList(result);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchLocations = async () => {
      const result = await EpdService.getLocations();
      setLocationList(
        result.map((x: GeographicalScopeModel) => {
          return { value: x.id, label: x.name } as Option;
        })
      );
    };
    const fetchLanguages = async () => {
      const result = await LanguagesService.getLanguages();
      setLanguages(result);
    };
    const fetchDocumentType = async () => {
      const res = await UploadDocumentTypesService.getDocumentTypes();
      setDocumentTypes(res);
      return res;
    };
    const fetchDataSourceVersions = async () => {
      const res = await DataSourceVersionsService.getDataSourceVersions();
      setDataSourceVersions(res);
      return res;
    };
    const fetchDataSources = async () => {
      const res = await DataSourcesService.getDataSources();
      setDataSources(res);
      fetchDataSourcesRows();
      return res;
    };
    const fetchDataSourcesRows = async () => {
      setDataSourceRowValues(epd?.epdDataSources ?? []);
    };
    const fetchMaterialProperties = async () => {
      const res = await MaterialPropertiesService.getMaterialProperties();
      setMaterialProperties(res);
      const epdMaterialProperties = epd?.productFlow?.materialPropertyItems?.map((item) => {
        const res2 = res.find((x) => x.id === item.materialPropertyId);
        return {
          id: res2?.id,
          name: res2?.name,
          value: item.value,
        };
      });
      setRowValues(epdMaterialProperties ?? []);
    };

    const fetchFlowProperties = async () => {
      const res = await FlowPropertiesService.getFlowProperties();
      const flowPropertyId = epd?.productFlow?.flowPropertyItem?.flowPropertyId;
      const res2 = res.find((item) => item.id === flowPropertyId);
      const excludedFlowProperties = [
        '-',
        'area (m^2)',
        'Carbon content (biogenic)',
        'Carbon content (biogenic) - packaging',
        'number of pieces (pieces)',
        'volume (m^3)',
        'UNDEFINED',
      ];
      setFlowProperties(res.filter((x) => !excludedFlowProperties.includes(x.name!)));
      setFlowPropertyOption({ value: flowPropertyId, label: res2?.name });
    };

    const fetchUNCPCCodes = async () => {
      const result = await CpcService.getCpcs(undefined, undefined, undefined);
      setLookupUNCPCCodes(
        result.map((x) => {
          return { label: x.description, value: x.id } as Option;
        })
      );
    };

    fetchUNCPCCodes();
    fetchCollections();
    fetchLanguages();
    fetchDocumentType();
    fetchLocations();
    fetchMaterialProperties();
    fetchFlowProperties();
    fetchDataSources();
    fetchDataSourceVersions();
    fetchDataSourcesRows();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];
    if (!file || !epd) {
      return null;
    }

    onChangeEpd && onChangeEpd(propertyName, file);
  };

  const changeCollection = async (newCollections: MultiValue<Option>) => {
    const oldCollectionValues = collections?.map((item: any) => item.value);
    const newCollectionValues = newCollections?.map((item: any) => item.value);
    const valuesToAdd = newCollectionValues?.filter((item: any) => !oldCollectionValues?.includes(item));
    const valuesToRemove = oldCollectionValues?.filter((item: any) => !newCollectionValues?.includes(item));
    await Promise.all(
      valuesToAdd?.map((item: any) => {
        return CollectionService.addCollectionEPD(item as string, epd?.id as string);
      }) ?? []
    );
    await Promise.all(
      valuesToRemove?.map((item: any) => {
        return CollectionService.removeCollectionEpd(item as string, epd?.id as string);
      }) ?? []
    );
    onChangeEpd(undefined, undefined, true);
  };
  const renameAndUploadFile = async (
    e: ChangeEvent<HTMLInputElement>,
    propertyName: string,
    docTypeId: string,
    docLangId: string
  ) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !epd) {
      return null;
    }
    if (docTypeId !== '2489c33d-56f6-4fe7-b5cd-a94e82900708') {
      propertyName = 'DocumentOther';
    }

    let newName = renameDocument(docLangId, docTypeId, 0, file?.name.split('.').pop());
    onChangeEpd && onChangeEpd(propertyName, new File([file], newName, { type: file.type }));
  };

  const renameDocument = (docLangId: string, docTypeId: string, dublicates: number, fileExtension: any) => {
    let numDoc = dublicates === 0 ? '' : `(${dublicates})`;
    const docLang = languageList.filter((x) => x.id === docLangId).map((x) => x.code)[0];
    const docType = documentTypeList.filter((x) => x.id === docTypeId).map((x) => x.name)[0];
    let newName = `${docType} ${epd?.fullIdentificationNumber} ${docLang}${numDoc}.${fileExtension}`;
    if (!epd || !epd.documents) {
      return newName;
    }
    if (epd.documents.filter((x) => x.name === newName).length > 0) {
      dublicates += 1;
      newName = renameDocument(docLangId, docTypeId, dublicates, fileExtension);
    }

    return newName;
  };
  const changeLocation = (locations?: MultiValue<Option>) => {
    if (Array.isArray(locations)) {
      const selectedLocations = locations.map((x) => x.value);
      onChangeEpd('GeographicalScopes', selectedLocations);
    } else {
      onChangeEpd('GeographicalScopes', undefined);
    }
  };

  const changeSubtype = async (subType?: SingleValue<SubtypeOption>) => {
    if (subType) {
      const selectedSubType = subType.value;
      onChangeEpd('subtype', selectedSubType);
    }
  };

  const changeProductFlowValues = (name?: string, flowPropertyId?: string, conversionFactor?: number) => {
    let newValue: any = {
      Name: name,
    };
    if (newValue.Name === '') {
      return newValue;
    }
    if (flowPropertyId && conversionFactor) {
      newValue = {
        ...newValue,
        FlowPropertyItem: {
          FlowPropertyId: flowPropertyId,
          ConversionFactor: conversionFactor,
        },
      };
    }
    return newValue;
  };

  const collections =
    epd?.collections &&
    epd?.collections?.map((x) => {
      return { label: x.name, value: x.id } as Option;
    });

  const geoScopes =
    epd?.geographicalScopes &&
    epd?.geographicalScopes.map((x) => {
      return { label: x.name, value: x.id } as Option;
    });

  const saveRowValues = (values: any[]) => {
    const filteredRows = values.filter((x) => x.id !== '' && x.value !== '');
    const valuesToSave: any = filteredRows.map((row) => {
      return {
        MaterialPropertyId: row.id,
        Value: row.value,
      };
    });
    onChangeEpd('MaterialProperties', valuesToSave);
  };
  const saveDataSourceRowValues = (values: any[]) => {
    const valuesToSave: any = values.map((row) => {
      return {
        Id: row.id,
        DataSource: { id: row.dataSource.id, name: row.dataSource.name },
        DataSourceVersion: { id: row.dataSourceVersion.id, name: row.dataSourceVersion.name },
      };
    });
    onChangeEpd('DataSourcesProperties', valuesToSave);
  };

  function handleProductFlowValueChange(event: any) {
    const { value } = event.target;
    const formattedProductFlowValue = value.replace(/^0+(?=\d)/, ''); // Removes leading zeros, except one before a period
    event.target.value = formattedProductFlowValue;
  }

  const handleProductFlowValueKeyDown = (event: any) => {
    const {
      code,
      target: { value },
    } = event;
    const fractionalNumWithOneDigitPattern = /^0\.\d$/;
    if (code === 'Backspace' && fractionalNumWithOneDigitPattern.test(value)) {
      event.target.value = '';
      return;
    }
    if (code !== 'Minus') {
      return;
    }
    event.preventDefault();
  };

  const handleProductFlowValuePaste = (event: any) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.Clipboard;
    const pastedValue = clipboardData.getData('text');

    if (pastedValue === '0') {
      // Inserts the text at the current caret position
      document.execCommand('insertText', false, '0.01');
      return;
    }

    let formattedProductFlowValue = pastedValue
      .replace(/^0+(?=\d)/, '') // Removes leading zeros, except one before a period
      .replace(/-/g, ''); // Removes minuses

    document.execCommand('insertText', false, formattedProductFlowValue);
  };

  const handleChangedProductFlow = (fieldName: string, value: any) => {
    setSelectedConversionFactor(value);
    onChangeEpd(fieldName, changeProductFlowValues(epd?.productFlow?.name, selectedFlowPropertyId, value));
  };

  const onChangeMaterialUnit = (data: any, index: number) => {
    const newValues = [...rowValues];
    newValues[index] = { value: newValues[index].value, id: data?.value, name: data?.label };
    setRowValues(newValues);
    saveRowValues(newValues);
  };

  const onChangeMaterialValue = (value: number, index: number, save: boolean = false) => {
    const newValues = [...rowValues];
    newValues[index].value = value;
    setRowValues(newValues);
    if (save) {
      saveRowValues(newValues);
    }
  };

  const onChangeDataSource = (data: any, index: number, propertyName: string) => {
    const newValues = [...rowDataSourceValues];

    if (propertyName === 'DataSource') {
      newValues[index].dataSource = { id: data?.value, name: data?.label };
      newValues[index].dataSourceVersion = { id: undefined, name: '' };
    } else if (propertyName === 'DataSourceVersion') {
      newValues[index].dataSourceVersion = { id: data?.value, name: data?.label };
    }
    setDataSourceRowValues(newValues);
    saveDataSourceRowValues(newValues);
  };

  const createRow = () => {
    const newRowValues = [...rowValues, { id: '', name: '', value: '' }];
    setRowValues(newRowValues);
  };

  const deleteRow = (index: number) => {
    const newValues = [...rowValues];
    newValues.splice(index, 1);
    setRowValues([...newValues]);
    saveRowValues(newValues);
  };

  const createDataSourceRow = () => {
    const newRowValues = [
      ...rowDataSourceValues,
      { id: undefined, dataSource: { id: undefined, name: '' }, dataSourceVersion: { id: undefined, name: '' } },
    ];
    setDataSourceRowValues(newRowValues);
  };
  const deleteDataSourceRow = (index: number) => {
    const newValues = [...rowDataSourceValues];
    newValues.splice(index, 1);
    setDataSourceRowValues([...newValues]);
    saveDataSourceRowValues(newValues);
  };

  const referencePackageVersions15804Options: Option[] =
    referencePackageVersions15804?.versions.map((r) => ({
      label: r.name,
      value: r.id,
    })) ?? [];

  const materialPropertiesInfo = (
    <>
      {t('epdWizard.wizardStep2.info.materialProperties.declaration')}
      <br />
      <br />
      {t('epdWizard.wizardStep2.info.materialProperties.description')}
      <ul>
        <li>{t('epdWizard.wizardStep2.info.materialProperties.prop1')}</li>
        <li>{t('epdWizard.wizardStep2.info.materialProperties.prop2')}</li>
        <li>{t('epdWizard.wizardStep2.info.materialProperties.prop3')}</li>
      </ul>
      {t('epdWizard.wizardStep2.info.materialProperties.footer')}
    </>
  );

  const subTypeInfo = (
    <>
      {t('epdWizard.wizardStep2.info.subType.description')}
      <br />
      <br />- {t('epdWizard.wizardStep2.info.subType.subType1')}
      <br />- {t('epdWizard.wizardStep2.info.subType.subType3')}
      <br />- {t('epdWizard.wizardStep2.info.subType.subType4')}
      <br />- {t('epdWizard.wizardStep2.info.subType.subType5')}
    </>
  );

  const gtinInfo = (
    <>
      {t('epdWizard.wizardStep2.info.gtin.gtin1')}&nbsp;
      <a href="https://www.gs1.org/standards/id-keys/gtin" target="_blank" rel="noopener noreferrer">
        Global Trade Item Number
      </a>
      &nbsp;
      {t('epdWizard.wizardStep2.info.gtin.gtin2')}
    </>
  );

  const referenceInfo = (
    <>
      {t('epdWizard.wizardStep2.info.referenceFlows.ref1')}
      <br />
      <br />
      {t('epdWizard.wizardStep2.info.referenceFlows.ref2')}
    </>
  );

  const techInfo = (
    <>
      {t('epdWizard.wizardStep2.info.technology.tech1')}
      <br />
      <br />
      {t('epdWizard.wizardStep2.info.technology.tech2')}
      <br />
      <br />- {t('epdWizard.wizardStep2.info.technology.tech3')}
      <br />- {t('epdWizard.wizardStep2.info.technology.tech4')}
      <br />- {t('epdWizard.wizardStep2.info.technology.tech5')}
      <br />- {t('epdWizard.wizardStep2.info.technology.tech6')}
      <br />
      <br />
      {t('epdWizard.wizardStep2.info.technology.tech7')}
    </>
  );

  return (
    <div>
      <H3 style={{ marginTop: 0 }}>{t('epdWizard.wizardStep2.properties.infoPublic')}</H3>

      <FixedRow state={epd?.documentsState.actionState}>
        <FixedLabelTop>{t('epdWizard.wizardStep2.properties.collections')}</FixedLabelTop>
        <div style={{ width: '100%' }}>
          <InfoBox>
            <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
            <div
              style={{ textAlign: 'justify' }}
              dangerouslySetInnerHTML={{ __html: t('manageCollections.infoDetailed') }}
            />
          </InfoBox>
          <SelectWrapper>
            <Select
              options={
                collectionList.map((x: CollectionModel) => {
                  return { value: x.id, label: x.name } as Option;
                }) as any
              }
              isMulti={true}
              isDisabled={false}
              styles={customSelectStyles}
              isClearable={true}
              defaultValue={collections}
              onChange={(e) => changeCollection(e)}
            />
          </SelectWrapper>
        </div>
      </FixedRow>
      <FixedRow state={epd?.documentsState.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.documents')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.documents')} />
          <Validation validation={validation} epd={epd} field="documents" />
        </FixedLabelTop>
        <div style={{ width: '100%' }}>
          {epd?.documentTemplateUrlState?.actionState !== ActionState.Hidden && (
            <>
              <InfoBox>
                <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
                <div style={{ textAlign: 'justify' }}>
                  {t('epdWizard.wizardStep2.info.documents')}
                  <br />
                  <A href={EPD_DEVELOPMENT_VERIFICATION_TEMPLATES_URL} target="_blank" rel="noopener noreferrer">
                    {t('download.selectTemplate')}
                  </A>
                </div>
              </InfoBox>
              {epd?.isEn15804Compliant ? (
                <InfoBox>
                  <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
                  <div style={{ textAlign: 'justify' }}>
                    {t('epdWizard.wizardStep2.info.logotype')}
                    <br />
                    <A href={process.env.PUBLIC_URL + '/ECO-EPD-logo.zip'} download>
                      {t('download.logotype')}
                    </A>
                  </div>
                  <img
                    style={{ marginTop: '-1rem', width: '8rem', height: '7rem' }}
                    alt="logo"
                    src={process.env.PUBLIC_URL + '/ECO-EPD-Logo.png'}
                  />
                </InfoBox>
              ) : null}
            </>
          )}

          <UploadFile
            accept=".pdf"
            title={t('epdWizard.wizardStep2.upload.epdDocuments')}
            uploadFile={renameAndUploadFile}
            disabled={epd?.documentsState.actionState === ActionState.Disabled}
            documents={epd?.documents}
            onChangeFile={onChangeEpd}
            field="Document"
            languageList={languageList}
            documentTypeList={documentTypeList}
          />
        </div>
      </FixedRow>
      <EditorFixedRow state={epd?.textState.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.productDescription')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.productDescription')} />
          <Validation validation={validation} epd={epd} field="text" />
        </FixedLabelTop>
        <HTMLEditor
          editorStyle={epd?.textState.actionState === ActionState.Disabled ? hiddenEditorStyle : visibleEditorStyle}
          value={epd?.text}
          toolbarHidden={epd?.textState.actionState === ActionState.Disabled ? true : false}
          toolbar={customToolbarEpd}
          onEdit={(newValue: string) => onChangeEpd('text', newValue)}
          disabled={epd?.textState.actionState === ActionState.Disabled}
          stripPastedStyles={true}
        ></HTMLEditor>
      </EditorFixedRow>
      <FixedRow state={epd?.uncpcCodeState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep2.properties.cpc')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.cpcUnCode')} />
        </FixedLabel>
        <SelectWrapper>
          <Select
            options={lookupUNCPCCodes}
            isDisabled={epd?.uncpcCodeState.actionState === ActionState.Disabled}
            styles={customSelectStyles}
            isClearable={true}
            defaultValue={{ label: epd?.uncpcCode?.description, value: epd?.uncpcCode?.id }}
            onChange={(newValue: any | Option) => {
              onChangeEpd('uncpcCode', newValue?.value);
            }}
          />
        </SelectWrapper>
      </FixedRow>
      <EditorFixedRow state={epd?.productsState.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.products')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.products')} />
          <Validation validation={validation} epd={epd} field="products" />
        </FixedLabelTop>
        <HTMLEditor
          editorStyle={epd?.productsState.actionState === ActionState.Disabled ? hiddenEditorStyle : visibleEditorStyle}
          value={epd?.products}
          toolbarHidden={epd?.productsState.actionState === ActionState.Disabled ? true : false}
          toolbar={customToolbarEpd}
          onEdit={(newValue: string) => onChangeEpd('products', newValue)}
          disabled={epd?.productsState.actionState === ActionState.Disabled}
        ></HTMLEditor>
      </EditorFixedRow>
      <FixedRow state={epd?.productImagesState.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.images')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.images')} />
          <Validation validation={validation} epd={epd} field="productImages" />
        </FixedLabelTop>
        <div>
          <ImagesUpload
            name={t('epdWizard.wizardStep2.properties.images')}
            images={epd?.productImages}
            onUpload={(e) => uploadFile(e, 'ProductImage')}
            onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
            onChangeEpd={onChangeEpd}
            disabled={epd?.productImagesState.actionState === ActionState.Disabled}
          />
        </div>
      </FixedRow>
      <FixedRow state={epd?.geographicalScopesState.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep2.properties.geoScope')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.location')} />
          <Validation validation={validation} epd={epd} field="geographicalScopes" />
        </FixedLabel>
        <SelectWrapper>
          <Select
            options={locationList}
            isMulti={true}
            isDisabled={epd?.geographicalScopesState.actionState === ActionState.Disabled}
            styles={customSelectStyles}
            isClearable={true}
            defaultValue={geoScopes}
            onChange={(e) => changeLocation(e)}
          />
        </SelectWrapper>
      </FixedRow>
      <H3>{t('epdWizard.wizardStep2.properties.infoReadable')}</H3>
      <h3 style={{ fontWeight: 400, fontSize: '14px' }}>{t('epdWizard.wizardStep2.properties.descriptionReadable')}</h3>
      <FixedRow state={epd?.gtinState.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.gtin')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.gtin')} />
          <Validation validation={validation} epd={epd} field="gtin" />
        </FixedLabelTop>
        <div style={{ width: '100%' }}>
          <InfoBox style={{ alignItems: 'center', padding: '0.5rem 0', marginBottom: 0 }}>
            <div style={{ marginRight: '1rem', paddingLeft: '0.2rem', alignSelf: 'flex-start' }}></div>
            {infoArrowItems.gtin ? <ArrowUpBox>{gtinInfo}</ArrowUpBox> : <ArrowDownBox>{gtinInfo}</ArrowDownBox>}
            {t('epdWizard.wizardStep2.info.gtin').length >= 73 &&
              (infoArrowItems.gtin ? (
                <ArrowImage
                  style={{ paddingRight: '1rem' }}
                  src={arrowUp}
                  alt="Arrow up"
                  onClick={() => toggleOpen('gtin')}
                />
              ) : (
                <ArrowImage
                  style={{ paddingRight: '1rem' }}
                  src={arrowDown}
                  alt="Arrow down"
                  onClick={() => toggleOpen('gtin')}
                />
              ))}
          </InfoBox>
          <div style={{ boxSizing: 'border-box', width: '100%' }}>
            <FixedTextAreaComponent
              value={epd?.gtin}
              propertyName={'gtin'}
              onChangeEpd={onChangeEpd}
              disabled={epd?.gtinState.actionState === ActionState.Disabled}
            />
          </div>
        </div>
      </FixedRow>
      <FixedRow state={epd?.lcAsState.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.lcaResults')}
          <Validation validation={validation} epd={epd} field="lcAs" />
        </FixedLabelTop>
        <div style={{ width: '100%' }}>
          {epd?.lcaTemplateUrlState?.actionState !== ActionState.Hidden && (
            <InfoBox>
              <div>
                <div style={{ marginRight: '1rem', paddingLeft: '0.25rem', alignSelf: 'flex-start' }}></div>
                {t('epdWizard.wizardStep2.info.lca')}&nbsp;
                <a href="https://www.environdec.com/indicators" target="_blank" rel="noopener noreferrer">
                  {t('website')}
                </a>
                .
                <br />
                <A href={EPD_LCA_TEMPLATES_URL} target="_blank" rel="noopener noreferrer">
                  {t('download.selectTemplate')}
                </A>
              </div>
            </InfoBox>
          )}
          {epd?.lcAsState?.actionState === ActionState.Locked && (
            <div style={{ backgroundColor: theme.colors.lightOrange }}>
              This section is locked for maintenance or other reasons by admin.
            </div>
          )}
          {epd?.isEn15804Compliant && (
            <InfoBox>
              <div>
                <div style={{ marginRight: '1rem', paddingLeft: '0.25rem', alignSelf: 'flex-start' }}></div>
                {t('epdWizard.wizardStep2.info.lcaValidateText')}
                <br />
                <A href={EpdLinks.lcaValidation()} target="blank" rel="noopener">
                  {t('epdWizard.wizardStep2.info.lcaValidate')}
                </A>
              </div>
            </InfoBox>
          )}

          <UploadFile
            accept=".xlsx"
            title={t('epdWizard.wizardStep2.upload.lcaResults')}
            uploadFile={uploadFile}
            disabled={
              epd?.lcAsState?.actionState === ActionState.Locked || epd?.lcAsState?.actionState === ActionState.Disabled
            }
            documents={epd?.lcAs}
            onChangeFile={onChangeEpd}
            field="LCA"
            singleFile
          />
        </div>
      </FixedRow>
      <FixedRow state={epd?.referenceFlowsState.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.referenceFlows.title')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.referenceFlows')} />
          <Validation validation={validation} epd={epd} field="referenceFlows" />
        </FixedLabelTop>
        <Box style={{ paddingTop: '0.5rem' }}>
          <div style={{ width: '100%', paddingBottom: '1rem' }}>
            <InfoBox style={{ alignItems: 'center', padding: '0.5rem 0', marginBottom: 0, borderLeft: 'none' }}>
              {infoArrowItems.referenceFlows ? (
                <ArrowUpBox>{referenceInfo}</ArrowUpBox>
              ) : (
                <ArrowDownBox>{t('epdWizard.wizardStep2.info.referenceFlows.ref1')}</ArrowDownBox>
              )}
              {t('epdWizard.wizardStep2.info.referenceFlows.ref1').length >= 73 &&
                (infoArrowItems.referenceFlows ? (
                  <ArrowImage src={arrowUp} alt="Arrow up" onClick={() => toggleOpen('referenceFlows')} />
                ) : (
                  <ArrowImage src={arrowDown} alt="Arrow down" onClick={() => toggleOpen('referenceFlows')} />
                ))}
            </InfoBox>
          </div>
          <ReferenceGrid>
            <ReferenceColumn>
              <Label>{t('epdWizard.wizardStep2.properties.referenceFlows.productFlowName')}</Label>
            </ReferenceColumn>
            <ReferenceColumn>
              <FixedTextInputComponent
                value={epd?.productFlow?.name}
                propertyName={'productFlow'}
                onChangeEpd={(fieldName: string, value: any) => {
                  onChangeEpd(
                    fieldName,
                    changeProductFlowValues(value ? value : '', selectedFlowPropertyId, selectedConversionFactor)
                  );
                }}
                disabled={epd?.referenceFlowsState?.actionState === ActionState.Disabled}
                style={productFlowInput}
              />
            </ReferenceColumn>
            <Column>
              <Label>{t('epdWizard.wizardStep2.properties.referenceFlows.unit')}</Label>
            </Column>
            <Column>
              <Label>{t('epdWizard.wizardStep2.properties.referenceFlows.conversionFactor')}</Label>
            </Column>
            <Column>
              <Select
                options={flowProperties.map((x: FlowPropertyModel) => {
                  return { value: x.id, label: x.name } as Option;
                })}
                value={flowPropertyOption as Option}
                isMulti={false}
                isDisabled={epd?.referenceFlowsState?.actionState === ActionState.Disabled || isFetching}
                styles={SelectStyle}
                isClearable={true}
                onChange={(event: any) => {
                  if (event) {
                    setFlowPropertyOption(event);
                    setSelectedFlowPropertyId(event.value);
                    onChangeEpd(
                      'productFlow',
                      changeProductFlowValues(epd?.productFlow?.name, event.value, selectedConversionFactor)
                    );
                  } else {
                    setFlowPropertyOption({ value: undefined, label: undefined });
                    setSelectedConversionFactor(undefined);
                    onChangeEpd('productFlow', changeProductFlowValues(epd?.productFlow?.name, undefined, undefined));
                  }
                }}
              />
            </Column>
            <Column>
              <FixedTextInputComponent
                value={epd?.productFlow?.flowPropertyItem?.conversionFactor}
                propertyName={'productFlow'}
                type="number"
                min="0.1"
                step="0.1"
                onKeyDown={handleProductFlowValueKeyDown}
                onPaste={handleProductFlowValuePaste}
                onChange={handleProductFlowValueChange}
                onChangeEpd={(fieldName: string, value: any) => handleChangedProductFlow(fieldName as string, value as any)}
                disabled={epd?.referenceFlowsState?.actionState === ActionState.Disabled || isFetching}
                style={productFlowInput}
                placeholder={t('placeholder.insertNumber')}
              />
            </Column>
          </ReferenceGrid>
        </Box>
      </FixedRow>
      <FixedRow style={{ marginBottom: '1rem' }} state={epd?.referenceFlowsState.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.materialProperties.title')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.materialProperties')} />
          <Validation validation={validation} epd={epd} field="materialProperties" />
        </FixedLabelTop>
        <Box style={{ paddingTop: '0.5rem' }}>
          <div style={{ width: '100%', paddingBottom: '1rem' }}>
            <InfoBox style={{ alignItems: 'center', padding: '0.5rem 0', marginBottom: 0, borderLeft: 'none' }}>
              {infoArrowItems.materialProperties ? (
                <ArrowUpBox>{materialPropertiesInfo}</ArrowUpBox>
              ) : (
                <ArrowDownBox>{t('epdWizard.wizardStep2.info.materialProperties.declaration')}</ArrowDownBox>
              )}
              {t('epdWizard.wizardStep2.info.materialProperties.declaration').length >= 73 &&
                (infoArrowItems.materialProperties ? (
                  <ArrowImage src={arrowUp} alt="Arrow up" onClick={() => toggleOpen('materialProperties')} />
                ) : (
                  <ArrowImage src={arrowDown} alt="Arrow down" onClick={() => toggleOpen('materialProperties')} />
                ))}
            </InfoBox>
          </div>
          <MaterialGrid>
            <MaterialColumn1>
              <Label>{t('epdWizard.wizardStep2.properties.materialProperties.propertyUnit')}</Label>
            </MaterialColumn1>
            <MaterialColumn2>
              <Label>{t('epdWizard.wizardStep2.properties.materialProperties.value')}</Label>
            </MaterialColumn2>
            <div></div>

            {rowValues.map((item, index) => (
              <React.Fragment key={index}>
                <MaterialColumn1>
                  <Select
                    options={materialProperties
                      .map((x: MaterialPropertyModel) => {
                        return { value: x.id, label: x.name } as Option;
                      })
                      .filter((option) => !rowValues.find((rowValue) => rowValue.id === option.value))}
                    value={{ value: item?.id, label: item?.name }}
                    isMulti={false}
                    isDisabled={epd?.referenceFlowsState?.actionState === ActionState.Disabled}
                    styles={SelectStyle}
                    isClearable={true}
                    onChange={(e) => onChangeMaterialUnit(e as any, index)}
                  />
                </MaterialColumn1>
                <MaterialColumn2>
                  <MaterialInput
                    value={item.value}
                    type="number"
                    placeholder={t('placeholder.insertNumber')}
                    disabled={epd?.referenceFlowsState?.actionState === ActionState.Disabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeMaterialValue(e.target.value as any, index, false)
                    }
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeMaterialValue(e.target.value as any, index, true)
                    }
                  />
                </MaterialColumn2>
                <MaterialColumn3 style={{ textAlign: 'center' }}>
                  <RemoveButton
                    type="button"
                    onClick={() => deleteRow(index)}
                    disabled={epd?.referenceFlowsState?.actionState === ActionState.Disabled}
                  >
                    {t('remove')}
                  </RemoveButton>
                </MaterialColumn3>
              </React.Fragment>
            ))}

            <MaterialColumn3>
              {rowValues.length < 6 && (
                <AddButton
                  type="button"
                  onClick={createRow}
                  disabled={
                    epd?.referenceFlowsState?.actionState === ActionState.Disabled ||
                    rowValues[rowValues.length - 1] === null
                  }
                >
                  + {t('add')}
                </AddButton>
              )}
            </MaterialColumn3>
          </MaterialGrid>
        </Box>
      </FixedRow>
      <FixedRow state={epd?.referenceYearState?.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep2.properties.referenceYear')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.referenceYear')} />
          <Validation validation={validation} epd={epd} field="referenceYear" />
        </FixedLabel>
        <FixedTextInputComponent
          value={epd?.referenceYear}
          propertyName={'referenceYear'}
          onChangeEpd={onChangeEpd}
          disabled={epd?.referenceYearState?.actionState === ActionState.Disabled}
        />
      </FixedRow>
      <FixedRow state={epd?.geographicalDescriptionState?.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.geoDescription')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.geo')} />
          <Validation validation={validation} epd={epd} field="geographicalDescription" />
        </FixedLabelTop>
        <div style={{ width: '100%' }}>
          <InfoBox style={{ alignItems: 'center', padding: '0.5rem 1rem', marginBottom: 0 }}>
            {infoArrowItems.geo ? (
              <ArrowUpBox>{t('epdWizard.wizardStep2.info.geo')}</ArrowUpBox>
            ) : (
              <ArrowDownBox>{t('epdWizard.wizardStep2.info.geo')}</ArrowDownBox>
            )}
            {t('epdWizard.wizardStep2.info.geo').length >= 73 &&
              (infoArrowItems.geo ? (
                <ArrowImage src={arrowUp} alt="Arrow up" onClick={() => toggleOpen('geo')} />
              ) : (
                <ArrowImage src={arrowDown} alt="Arrow down" onClick={() => toggleOpen('geo')} />
              ))}
          </InfoBox>
          <FixedTextInputComponent
            style={{ boxSizing: 'border-box', width: '100%' }}
            value={epd?.geographicalDescription}
            propertyName={'geographicalDescription'}
            onChangeEpd={onChangeEpd}
            disabled={epd?.geographicalDescriptionState?.actionState === ActionState.Disabled}
          />
        </div>
      </FixedRow>
      <FixedRow state={epd?.technologyDescriptionState?.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.techDescription')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.technology')} />
          <Validation validation={validation} epd={epd} field="technologyDescription" />
        </FixedLabelTop>
        <div style={{ width: '100%' }}>
          <InfoBox style={{ alignItems: 'center', padding: '0.5rem 1rem', marginBottom: 0 }}>
            {infoArrowItems.technology ? (
              <ArrowUpBox>{techInfo}</ArrowUpBox>
            ) : (
              <ArrowDownBox>{t('epdWizard.wizardStep2.info.technology.tech1')}</ArrowDownBox>
            )}
            {t('epdWizard.wizardStep2.info.technology').length >= 73 &&
              (infoArrowItems.technology ? (
                <ArrowImage src={arrowUp} alt="Arrow up" onClick={() => toggleOpen('technology')} />
              ) : (
                <ArrowImage src={arrowDown} alt="Arrow down" onClick={() => toggleOpen('technology')} />
              ))}
          </InfoBox>
          <FixedTextAreaComponent
            value={epd?.technologyDescription}
            propertyName={'technologyDescription'}
            onChangeEpd={onChangeEpd}
            disabled={epd?.technologyDescriptionState?.actionState === ActionState.Disabled}
          />
        </div>
      </FixedRow>
      <FixedRow state={epd?.technicalPurposeState?.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.techPurpose')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.technicalPurpose')} />
          <Validation validation={validation} epd={epd} field="technicalPurpose" />
        </FixedLabelTop>
        <div style={{ width: '100%' }}>
          <InfoBox style={{ alignItems: 'center', padding: '0.5rem 1rem', marginBottom: 0 }}>
            {infoArrowItems.technicalPurpose ? (
              <ArrowUpBox>{t('epdWizard.wizardStep2.info.technicalPurpose')}</ArrowUpBox>
            ) : (
              <ArrowDownBox>{t('epdWizard.wizardStep2.info.technicalPurpose')}</ArrowDownBox>
            )}
            {t('epdWizard.wizardStep2.info.technicalPurpose').length >= 73 &&
              (infoArrowItems.technicalPurpose ? (
                <ArrowImage src={arrowUp} alt="Arrow up" onClick={() => toggleOpen('technicalPurpose')} />
              ) : (
                <ArrowImage src={arrowDown} alt="Arrow down" onClick={() => toggleOpen('technicalPurpose')} />
              ))}
          </InfoBox>
          <FixedTextAreaComponent
            value={epd?.technicalPurpose}
            propertyName={'technicalPurpose'}
            onChangeEpd={onChangeEpd}
            disabled={epd?.technicalPurposeState?.actionState === ActionState.Disabled}
          />
        </div>
      </FixedRow>
      <FixedRow state={epd?.subtypeState?.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.subType')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.subType')} />
          <Validation validation={validation} epd={epd} field="subtype" />
        </FixedLabelTop>
        <div style={{ width: '100%' }}>
          <InfoBox style={{ alignItems: 'center', padding: '0.5rem 1rem', marginBottom: 0 }}>
            {t('epdWizard.wizardStep2.info.subType.description').length >= 73 &&
              (infoArrowItems.subType ? (
                <ArrowUpBox>{subTypeInfo}</ArrowUpBox>
              ) : (
                <ArrowDownBox>{t('epdWizard.wizardStep2.info.subType.description')}</ArrowDownBox>
              ))}
            {infoArrowItems.subType ? (
              <ArrowImage src={arrowUp} alt="Arrow up" onClick={() => toggleOpen('subType')} />
            ) : (
              <ArrowImage src={arrowDown} alt="Arrow down" onClick={() => toggleOpen('subType')} />
            )}
          </InfoBox>
          <SelectWrapper>
            <Select
              value={
                !!epd?.subtype
                  ? subtypeOptions.find((x) => x.value == epd?.subtype?.toString() || '')
                  : {
                      label: '',
                      value: '',
                    }
              }
              options={subtypeOptions}
              styles={customSelectStyles}
              isDisabled={epd?.subtypeState?.actionState === ActionState.Disabled}
              onChange={(e) => changeSubtype(e)}
            />
          </SelectWrapper>
        </div>
      </FixedRow>
      <FixedRow style={{ marginBottom: '1rem' }} state={epd?.epdDataSourcesState?.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.dataSources')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.dataSources')} />
          <Validation validation={validation} epd={epd} field="epdDataSources" />
        </FixedLabelTop>
        <Box style={{ paddingTop: '0.5rem' }}>
          <div style={{ width: '100%', paddingBottom: '1rem' }}>
            <InfoBox style={{ alignItems: 'center', padding: '0.5rem 0', marginBottom: 0, borderLeft: 'none' }}>
              {infoArrowItems.dataSources ? (
                <ArrowUpBox>{t('epdWizard.wizardStep2.info.dataSources')}</ArrowUpBox>
              ) : (
                <ArrowDownBox>{t('epdWizard.wizardStep2.info.dataSources')}</ArrowDownBox>
              )}
              {t('epdWizard.wizardStep2.info.dataSources').length >= 73 &&
                (infoArrowItems.dataSources ? (
                  <ArrowImage src={arrowUp} alt="Arrow up" onClick={() => toggleOpen('dataSources')} />
                ) : (
                  <ArrowImage src={arrowDown} alt="Arrow down" onClick={() => toggleOpen('dataSources')} />
                ))}
            </InfoBox>
          </div>
          <MaterialGrid>
            {rowDataSourceValues.map((item, index) => (
              <React.Fragment key={index}>
                <ReferenceColumn style={{ padding: '5px 0', display: 'grid', rowGap: '3px' }}>
                  <Select
                    options={dataSources.map((x: DataSourceModel) => {
                      return { value: x.id, label: x.name } as Option;
                    })}
                    value={item?.dataSource?.id ? { value: item?.dataSource?.id, label: item?.dataSource?.name } : null}
                    isDisabled={epd?.epdDataSourcesState?.actionState === ActionState.Disabled}
                    styles={SelectStyle}
                    isClearable
                    placeholder={t('placeholder.defaultSelectDatabase')}
                    onChange={(e) => onChangeDataSource(e as any, index, 'DataSource')}
                  />
                  <Select
                    options={dataSourceVersions
                      .filter((dataSourceVersionItem) => dataSourceVersionItem.dataSourceId === item?.dataSource?.id)
                      .map((x: DataSourceVersionModel) => {
                        return { value: x.id, label: x.name, dataSourceId: x.dataSourceId } as Option;
                      })}
                    value={
                      item?.dataSourceVersion?.id
                        ? { value: item?.dataSourceVersion?.id, label: item?.dataSourceVersion?.name }
                        : null
                    }
                    isDisabled={!item?.dataSource?.id || epd?.epdDataSourcesState?.actionState === ActionState.Disabled}
                    styles={SelectStyle}
                    isClearable
                    placeholder={t('placeholder.defaultSelectDatabaseVersion')}
                    onChange={(e) => onChangeDataSource(e as any, index, 'DataSourceVersion')}
                  />
                </ReferenceColumn>
                <MaterialColumn3 style={{ textAlign: 'center', alignItems: 'start', alignSelf: 'start' }}>
                  <RemoveButton
                    type="button"
                    onClick={() => deleteDataSourceRow(index)}
                    disabled={epd?.epdDataSourcesState?.actionState === ActionState.Disabled}
                  >
                    {t('remove')}
                  </RemoveButton>
                </MaterialColumn3>
              </React.Fragment>
            ))}

            <MaterialColumn3>
              {rowDataSourceValues.length < 6 && (
                <AddButton
                  type="button"
                  onClick={createDataSourceRow}
                  disabled={epd?.epdDataSourcesState?.actionState === ActionState.Disabled}
                >
                  + {t('add')}
                </AddButton>
              )}
            </MaterialColumn3>
          </MaterialGrid>
        </Box>
      </FixedRow>
      <FixedRow state={epd?.referencePackageVersion15804State.actionState}>
        <FixedLabel>
          {t('epdWizard.wizardStep2.properties.referencePackageVersion15804')}
          <Validation validation={validation} epd={epd} field="referencePackageVersion15804" />
        </FixedLabel>
        <SelectWrapper>
          <Select
            options={referencePackageVersions15804Options}
            value={
              epd?.referencePackageVersion15804
                ? referencePackageVersions15804Options.find((o) => o.value === epd.referencePackageVersion15804)
                : null
            }
            isDisabled={epd?.referencePackageVersion15804State.actionState === ActionState.Disabled}
            styles={customSelectStyles}
            onChange={(e) => onChangeEpd('referencePackageVersion15804', e?.value)}
          />
        </SelectWrapper>
      </FixedRow>
      <FixedRow state={epd?.characterisationMethodsState.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.characterisationMethods')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.characterisationMethods')} />
        </FixedLabelTop>
        <FixedTextAreaComponent
          value={epd?.characterisationMethods}
          propertyName="characterisationMethods"
          onChangeEpd={onChangeEpd}
          disabled={epd?.characterisationMethodsState.actionState === ActionState.Disabled}
        />
      </FixedRow>

      <FixedRow state={epd?.useAdviceState?.actionState}>
        <FixedLabelTop>
          {t('epdWizard.wizardStep2.properties.useAdvice')}
          <HelpIcon content={t('epdWizard.wizardStep2.tooltips.useForDatasets')} />
          <Validation validation={validation} epd={epd} field="useAdvice" />
        </FixedLabelTop>
        <div style={{ width: '100%' }}>
          <InfoBox style={{ alignItems: 'center', padding: '0.5rem 1rem', marginBottom: 0 }}>
            {infoArrowItems.useForDatasets ? (
              <ArrowUpBox>{t('epdWizard.wizardStep2.info.useForDatasets')}</ArrowUpBox>
            ) : (
              <ArrowDownBox>{t('epdWizard.wizardStep2.info.useForDatasets')}</ArrowDownBox>
            )}
            {t('epdWizard.wizardStep2.info.useForDatasets').length >= 73 &&
              (infoArrowItems.useForDatasets ? (
                <ArrowImage src={arrowUp} alt="Arrow up" onClick={() => toggleOpen('useForDatasets')} />
              ) : (
                <ArrowImage src={arrowDown} alt="Arrow down" onClick={() => toggleOpen('useForDatasets')} />
              ))}
          </InfoBox>
          <FixedTextAreaComponent
            value={epd?.useAdvice}
            propertyName={'useAdvice'}
            onChangeEpd={onChangeEpd}
            disabled={epd?.useAdviceState?.actionState === ActionState.Disabled}
          />
        </div>
      </FixedRow>
    </div>
  );
};

const ArrowUpBox = styled.div`
  flex-grow: 1;
  width: 450px;
  margin-right: 1rem;
  white-space: wrap;
`;

const ArrowDownBox = styled(ArrowUpBox)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ArrowImage = styled.img`
  width: 1rem;
  height: 0.7rem;
  align-self: flex-start;
  margin-top: 6px;
`;

export const Box = styled.div`
  width: 100%;
  padding: 1rem;
  border-left: solid 3px green;
  margin: 0.5rem 0;
  background-color: #f3f3f3;
`;

const ReferenceGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  width: 100%;
`;

const MaterialGrid = styled.div`
  display: grid;
  grid-template-columns: 6fr 3fr 1fr;
  grid-template-rows: 3;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  width: 100%;
`;

const MaterialInput = styled.input`
  border-radius: 0;
  border-color: gray;
  border-width: 1px;
  padding-top: 0;
  padding-left: 8px;
  box-sizing: border-box;
  width: 100%;
  height: 49px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
`;

const RemoveButton = styled(ButtonSmall)`
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.8rem;
  background: rgb(105, 105, 105);
  :hover {
    background: rgb(128, 128, 128);
  }
`;

const AddButton = styled(ButtonSmall)`
  padding: 0.5rem;
  margin: 0 0 0 0.8rem;
  font-size: 0.8rem;
`;

const Column = styled.div`
  align-self: center;
  width: 100%;
`;
const ReferenceColumn = styled(Column)`
  grid-column-start: 1;
  grid-column-end: 3;
`;

const MaterialColumn1 = styled(Column)`
  grid-column-start: 1;
  grid-column-end: 2;
`;

const MaterialColumn2 = styled(Column)`
  grid-column-start: 2;
  grid-column-end: 3;
`;
const MaterialColumn3 = styled(Column)`
  justify-self: end;
  grid-column-start: 3;
  grid-column-end: 4;
`;

const FixedTextInputComponent: React.FunctionComponent<{
  value: any;
  propertyName: string;
  onKeyDown?: any;
  onPaste?: any;
  onChange?: any;
  onChangeEpd: any;
  disabled: boolean;
  style?: any;
  placeholder?: string;
  type?: string;
  min?: string;
  step?: string;
}> = ({ value, propertyName, onKeyDown, onPaste, onChange, onChangeEpd, disabled, style, placeholder, type, min, step }) => {
  return (
    <FixedTextInput
      style={style}
      type={type}
      min={min}
      step={step}
      defaultValue={value}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
      onChange={onChange}
      onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
        e.currentTarget.value !== value?.toString() && onChangeEpd(propertyName, e.currentTarget.value)
      }
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

const FixedTextAreaComponent: React.FunctionComponent<{
  value: any;
  propertyName: string;
  onChangeEpd: any;
  disabled: boolean;
}> = ({ value, propertyName, onChangeEpd, disabled }) => {
  return (
    <FixedTextArea
      defaultValue={value}
      disabled={disabled === true}
      onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
        e.currentTarget.value !== value && onChangeEpd(propertyName, e.currentTarget.value)
      }
    />
  );
};

export const customSelectStyles = {
  menu: (styles: any) => ({ ...styles }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: 0,
    borderColor: 'gray',
    color: 'black',
    fontSize: '14px',
    minHeight: '49px',
    margin: '0.5rem 0 ',
    width: '100%',
    boxSizing: 'border-box',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontSize: '100%',
  }),
};

export const SelectStyle = {
  menu: (styles: any) => ({ ...styles }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: 0,
    borderColor: 'gray',
    color: 'black',
    fontSize: '14px',
    minHeight: '49px',
    width: '100%',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontSize: '100%',
  }),
};

export const UploadButton = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

export const FixedRow = styled.div<{ state?: ActionState }>`
  display: ${(props) => (props?.state === ActionState.Hidden ? 'none' : 'flex')};
  padding: 0.5rem 0;
  margin-top: 2rem;
  flex-wrap: wrap;
  ${(props) =>
    props.state === ActionState.Disabled &&
    css`
      label {
        color: gray;
      }
    `}

  ${(props) => props.theme.media.tablet} {
    flex-wrap: nowrap;
    margin-top: 0;
  }
`;

export const EditorFixedRow = styled(FixedRow)<{ state?: ActionState }>`
  margin-bottom: ${(props) => (props?.state === ActionState.Disabled ? 0 : '3vh')};
`;

export const FileBox = styled.div`
  background-color: ${(props) => props.theme.colors.regionColorLight};
  padding: 3rem;
  flex: 1 0 auto;

  > h3 {
    margin-top: 0;
  }
`;

export default WizardStep2;
