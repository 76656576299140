import EpdRoutes from 'routes/EpdRoutes';
import { generateExpireTime } from 'util/utils';
import queryClient from 'util/queryClient';
import {
  ConfirmEmailModel,
  ForgotPasswordModel,
  LastLoginCompanyModel,
  LoginModel,
  MenuUserInfo,
  RegisterModel,
  ResetPasswordModel,
  SecurityClient,
  UserModel,
} from './EpdClient';

const AUTH_TOKEN = 'auth_expires';

const poorMansPcr = ['moderator@environdec.com', 'secretariat@environdec.com', 'reviewchair@environdec.com'];

const poorMansAdminPcr = [
  'pavinee.nojpanya@environdec.com',
  'gustav.sandin@environdec.com',
  'johan.nilsson@environdec.com',
  'pcradmin@environdec.com',
];

const client = new SecurityClient(process.env.REACT_APP_API_URL);

interface Token {
  expires: number;
  user: UserModel;
}

class AuthService {
  static isAdmin = () => {
    const storageItem = localStorage.getItem(AUTH_TOKEN);
    const token: Token = storageItem && JSON.parse(storageItem);
    return token?.user?.roles?.some((x) => x.name === 'System administrator');
  };

  static getMenuUserInfo = (): MenuUserInfo => {
    const storageItem = localStorage.getItem(AUTH_TOKEN);
    const token: Token = storageItem && JSON.parse(storageItem);
    return token?.user;
  };

  static isCompanyOwner = (companyId: string): boolean => {
    const storageItem = localStorage.getItem(AUTH_TOKEN);
    const token: Token = storageItem && JSON.parse(storageItem);

    return token?.user?.memberships?.some((x) => x.companyId === companyId && x.roleName === 'EPD owner') || false;
  };

  static isPcrUser = () => {
    const storageItem = localStorage.getItem(AUTH_TOKEN);
    const token: Token = storageItem && JSON.parse(storageItem);

    return token?.user?.email && poorMansPcr.includes(token.user.email.toLowerCase());
  };

  static isPcrAdmin = () => {
    const storageItem = localStorage.getItem(AUTH_TOKEN);
    const token: Token = storageItem && JSON.parse(storageItem);

    return token?.user?.email && poorMansAdminPcr.includes(token.user.email.toLowerCase());
  };

  static isLicenseeAdmin = () => {
    const storageItem = localStorage.getItem(AUTH_TOKEN);
    const token: Token = storageItem && JSON.parse(storageItem);

    return token?.user?.roles?.some((x) => x.name === 'Licensee administrator');
  };

  static isAdminLicenseeInCompany = async (companyId: string): Promise<boolean> => {
    const companiesId = await client.getCompaniesForLicenseeAdmin();
    return companiesId.some((x) => x === companyId);
  };

  static isAuthenticated = () => {
    const storageItem = localStorage.getItem(AUTH_TOKEN);
    const token = storageItem && (JSON.parse(storageItem) as Token);

    if (!token || !token?.expires || token.expires < new Date().getTime()) {
      AuthService.removeToken();
      return false;
    }

    return true;
  };

  static login = async (values: LoginModel) => {
    queryClient.clear();
    
    //TODO: Set expiry from server and set up watchdog?
    const user = await client.login(values);

    const token: Token = {
      expires: generateExpireTime(),
      user: user,
    };
    AuthService.setToken(token);
    return user;
  };

  static loginAs = async (values: LoginModel) => {
    const user = await client.loginAs(values);

    const token: Token = {
      expires: generateExpireTime(),
      user: user,
    };
    AuthService.setToken(token);

    return user;
  };

  static relogin = async (values: LoginModel) => {
    const user = await client.relogin(values);

    const token: Token = {
      expires: generateExpireTime(),
      user: user,
    };
    AuthService.setToken(token);

    return user;
  };

  static setToken = (token: Token) => {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
  };

  static getUser = () => {
    const storageItem = localStorage.getItem(AUTH_TOKEN);
    const token: Token = storageItem && JSON.parse(storageItem);

    return token?.user;
  };

  static isLoggedin = () => {
    return localStorage.getItem(AUTH_TOKEN) != null;
  };

  static removeToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
  };

  static logout = async (history: any) => {
    try {
      await client.logout();
    } catch (error) {
    } finally {
      AuthService.removeToken();
      history.push(EpdRoutes.login);
    }
  };

  static updateUserLatestLoginCompanyId = async (model: LastLoginCompanyModel) => {
    if (this.isLoggedin()) {
      await client.updateUserLatestLoginCompanyId(model);
    }
  };

  static acceptNewTermsAndConditions = async (newTermsAndConditionsAccepted: boolean) => {
    const acceptedDate = await client.acceptNewTermsAndConditions(newTermsAndConditionsAccepted);

    const storageItem = localStorage.getItem(AUTH_TOKEN);
    const token: Token = storageItem && JSON.parse(storageItem);

    const tokenWithUpdatedUserAcceptedTermsData = {
      ...token,
      user: { ...token?.user, acceptsNewTermsOfConditionsDate: acceptedDate },
    } as Token;

    AuthService.setToken(tokenWithUpdatedUserAcceptedTermsData);
  };

  static confirmEmail = async (values: ConfirmEmailModel) => {
    await client.confirmEmail(values);
  };

  static resetPassword = async (values: ResetPasswordModel) => {
    await client.resetPassword(values);
  };

  static forgotPassword = async (values: ForgotPasswordModel) => {
    await client.forgotPassword(values);
  };

  static registerUser = async (values: RegisterModel, theme: string) => {
    await client.register(values, theme);
  };
}

export default AuthService;
