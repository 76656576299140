import { ActionState } from 'services/EpdClient';
import styled, { css } from 'styled-components';
import { Input, Label } from 'styles/Styles.styled';

export const AccordionLink = styled.div`
  cursor: pointer;
  align-self: center;
  padding-left: 1rem;
  &:hover {
    text-decoration: underline;
  }
`;

export const FixedLabel = styled(Label)`
  flex: 0 0 200px;
  align-self: center;
  margin: 0.5rem 1rem 0.5rem 0; /*risky change? */
  display: flex;
  gap: 0.25rem;
  > svg {
    margin: 0;
  }

  > div + svg {
    margin: 0;
  }
`;

export const FixedLabelTop = styled(FixedLabel)`
  align-self: auto;
`;

export const FixedRow = styled.div<{ state?: ActionState }>`
  display: ${(props) => (props?.state === ActionState.Hidden ? 'none' : 'flex')};

  padding: 0.5rem 0;

  ${(props) =>
    props.state === ActionState.Disabled &&
    css`
      label {
        color: gray;
      }
    `}
`;

export const FixedTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  margin: 0.5rem 0 1rem 0;
  box-sizing: border-box;
`;

export const FixedTextInput = styled(Input)`
  flex: 1 0 auto;
  box-sizing: border-box;
`;

export const SelectWrapper = styled.div`
  flex: 1 0 0;
  margin: 0.5rem 0 1rem 0;
`;

export const Tooltip = styled.div`
  display: none;
  ${(props) => props.theme.fonts.textSmall};
  text-transform: initial;
  position: absolute;
  background: ${(props) => props.theme.colors.darkGray};
  padding: 1rem;
  color: white;
  opacity: 0.85;
  z-index: 1;
`;
