import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardAddButton from 'components/v2/epd-wizard-inputs/WizardAddButton';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import { HelpBox } from 'components/v2/help-boxes';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import React, { useEffect, useState } from 'react';
import { useCreateEpdProductionSiteAddress, useDeleteAddress, useUpdateAddress } from 'services/api/mutations';
import { useCountriesLookup } from 'services/api/queries';
import {
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanel,
  FieldPanelFullWidth,
  HeaderCaptionSemiBold,
  HorizontalDivider,
} from 'styles/v2/Styles.styled';
import { EPDProductInformationModel, FoundationAddressModel, Option } from 'types/types';

import { DeleteProductIconButton } from '../../ProductInformationTab';
import ToggleButton from '../../panels/toggleable/ToggleButton';

const ProductionSitesSection: React.FunctionComponent<{
  product?: EPDProductInformationModel;
  epdVersionId: string;
  errors: any;
}> = ({ product, epdVersionId, errors }) => {
  const [productionSitesRows, setProductionSitesRows] = useState<FoundationAddressModel[] | undefined>(
    product?.productionSites
  );
  const countries = useCountriesLookup().data;
  const createMutation = useCreateEpdProductionSiteAddress();
  const deleteAddressMutation = useDeleteAddress();
  const updateAddressMutation = useUpdateAddress();

  const productionSiteAddressesErrors = (errors: any, index: any) => {
    return errors?.productionSiteBlock?.[`productionSiteAddresses[${index}]`];
  };

  useEffect(() => {
    setProductionSitesRows(product?.productionSites);
  }, [product?.productionSites]);

  const handleAddressUpdate = (field: string, value: string, id: string) => {
    const updateAddress = product?.productionSites?.find((item) => item.id === id);
    const newData = { ...updateAddress, [field]: value } as FoundationAddressModel;

    updateAddressMutation.mutate(newData);
  };

  const handleAddRow = (product: EPDProductInformationModel) => {
    createMutation.mutate({ productId: product.id, versionId: epdVersionId });
    const newRowValues = [
      ...(productionSitesRows ?? []),
      { id: '', city: '', countryId: '', zipCode: '', addressLine: '', addressLineSecond: '' },
    ];
    setProductionSitesRows(newRowValues);
  };

  const confirmDeleteAddress = (address: any) => {
    confirmDelete({
      caption: `Are you sure you want to delete production site address?`,
      onConfirm: () => deleteAddressMutation.mutate(address.id as string),
    });
  };

  const selectedCountry = (item: FoundationAddressModel) => {
    if (item?.countryId) {
      return {
        value: item.countryId,
        label: countries?.find((country: any) => country.id === item.countryId)?.name,
      } as Option;
    }
    return null;
  };

  function noErrorsAtProductionSitesBlock(errors: any): boolean | undefined {
    const fieldNames: string[] = ['productionSiteBlock'];

    return !errors || !fieldNames.some((field) => field in errors);
  }

  const headerModuleTemplate = (
    options: any,
    header: string,
    tooltip?: string,
    tooltipPosition?: any | undefined,
    required?: boolean,
    isFilledIn?: boolean
  ) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {required && !isFilledIn && <TooltipErrorIcon />}
          <ToggleButton options={options} />
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  return (
    <ExpandablePanelModule
      headerTemplate={(options) =>
        headerModuleTemplate(
          options,
          'Production site(s)',
          'Location of the production site(s), including, as a minimum, the city (or municipality, if not located in a city).',
          'top | right',
          true,
          noErrorsAtProductionSitesBlock(errors)
        )
      }
      toggleable
    >
      {productionSitesRows?.map((item, index) => (
        <FieldPanel key={index} style={{ paddingTop: '0', marginTop: '0' }}>
          <>
            {productionSitesRows?.length > 1 && index != 0 && (
              <div className="w-full">
                <HorizontalDivider style={{ marginBottom: '30px' }}></HorizontalDivider>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <HeaderCaptionSemiBold>{'Production site ' + (index + 1)}</HeaderCaptionSemiBold>
                  <DeleteProductIconButton icon="pi pi-trash" text onClick={() => confirmDeleteAddress(item)} />
                </div>
              </div>
            )}
            <CompilerSelectControlledComponent
              label="Country"
              name="country"
              options={countries?.map((country: any) => {
                return {
                  value: country?.id,
                  label: country?.name,
                } as Option;
              })}
              required
              value={selectedCountry(item)}
              onChanged={(field: string, value: string) => handleAddressUpdate('countryId', value, item.id)}
              placeholder="Select"
              error={productionSiteAddressesErrors(errors, index)?.country}
            />
            <WizardTextInputComponent
              label="City / Municipality"
              required
              placeholder="Type here"
              value={item.city}
              onChanged={(field: string, value: string) => handleAddressUpdate(field, value, item.id)}
              name="city"
              error={productionSiteAddressesErrors(errors, index)?.city}
            />
            <WizardTextInputComponent
              label="ZIP code"
              tooltipPosition={'right'}
              required
              placeholder="Type here"
              value={item.zipCode}
              onChanged={(field: string, value: string) => handleAddressUpdate(field, value, item.id)}
              name="zipCode"
              error={productionSiteAddressesErrors(errors, index)?.zipCode}
            />
            <WizardTextInputComponent
              label="Address line 1"
              placeholder="Type here"
              value={item.addressLine}
              onChanged={(field: string, value: string) => handleAddressUpdate(field, value, item.id)}
              name="addressLine"
            />
            <WizardTextInputComponent
              label="Address line 2"
              placeholder="Type here"
              value={item.addressLineSecond}
              onChanged={(field: string, value: string) => handleAddressUpdate(field, value, item.id)}
              name="addressLineSecond"
            />
          </>
        </FieldPanel>
      ))}
      <FieldPanelFullWidth style={{ gridRowGap: '5px' }}>
        <HelpBox>
          <span>You can add more addresses if the product is produced in more than one production site</span>
        </HelpBox>
        <WizardAddButton
          label="+ Add Address"
          onClick={() => {
            handleAddRow(product as EPDProductInformationModel);
          }}
        />
      </FieldPanelFullWidth>
    </ExpandablePanelModule>
  );
};

export default ProductionSitesSection;
