import { ChevronDownIcon, ChevronUpIcon } from 'components/v2/epd-compiler-inputs/common-icons';
import { PanelHeaderTemplateOptions } from 'primereact/panel';

const ToggleButton: React.FunctionComponent<{ options: PanelHeaderTemplateOptions; withMargins?: boolean }> = ({
  options,
  withMargins,
}) => {
  return (
    <button
      className={options.togglerClassName}
      onClick={options.onTogglerClick}
      style={withMargins ? { margin: '0 0.5rem' } : { width: '16px' }}
    >
      {options.collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
    </button>
  );
};

export default ToggleButton;
