import PasswordInput from 'components/form/PasswordInput';
import TextInput from 'components/form/TextInput';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { LoginModel, UserModel } from 'services/EpdClient';
import AdminService from 'services/admin/AdminService';
import styled, { css } from 'styled-components';
import {
  A,
  Button,
  ButtonLinkSmall,
  CheckBulletList,
  Container,
  ErrorText,
  H1Hero,
  H2,
  Half,
  StyledForm,
  Text,
} from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

interface TProps {
  changeTheme?: (theme: string) => void;
}

const Login: React.FunctionComponent<TProps> = ({ changeTheme }) => {
  const { changeCompany, setDefaultOrFirstCompany, setDefaultCompany } = useContext(CompanyContext);
  const [serverError, setServerError] = React.useState<string>();
  const [informationMessage, setInformationMessage] = React.useState<string>();
  const redirectUrl = window.sessionStorage.getItem('redirectUrl');
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const licensee = queryParams.get('licensee');

  const initialValues: LoginModel = {
    userName: process.env.REACT_APP_DEBUG && process.env.REACT_APP_LOGIN ? process.env.REACT_APP_LOGIN : '',
    password: process.env.REACT_APP_DEBUG && process.env.REACT_APP_PASSWORD ? process.env.REACT_APP_PASSWORD : '',
  };

  const validationSchema = Yup.object({
    userName: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });

  const redirectToDashboard = (result: any) => {
    let redirectLink;
    if (window.sessionStorage.getItem('redirectUrl')) {
      window.sessionStorage.removeItem('redirectUrl');
      redirectLink = redirectUrl as string;
    } else {
      redirectLink = AuthService.isPcrUser() ? EpdRoutes.pcr.dashboard : EpdLinks.dashboard();
    }

    if (result?.latestLoginCompanyId) {
      changeCompany?.(result?.latestLoginCompanyId);
    } else if (result?.memberships && result?.memberships.length > 0) {
      changeCompany?.(result?.memberships[0].companyId);
    }

    history.push(redirectLink);
  };

  const login = async (values: LoginModel) => {
    setServerError('');
    setDefaultCompany?.();
    let result: UserModel = {};
    try {
      result = await AuthService.login(values);
    } catch (e) {
      const error = PoorMansError(e);
      setServerError(error ?? 'Something went wrong. Try again later.');
      return;
    }

    redirectToDashboard(result);
  };

  useEffect(() => {
    const getPublicMessage = async () => {
      let info: string = '';
      try {
        info = await AdminService.getPublicInformationMessage();
      } finally {
        setInformationMessage(info);
      }
    };

    if (AuthService.isAuthenticated()) {
      redirectToDashboard(AuthService.getUser());
    } else {
      if (licensee && changeTheme) {
        changeTheme(licensee);
      }
      getPublicMessage();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {informationMessage && (
        <WarningContainer>
          <H2 style={{ fontSize: '1rem', color: 'red', justifySelf: 'start', display: 'block' }}>{informationMessage}</H2>
        </WarningContainer>
      )}
      <LoginContainer noTopPadding={informationMessage != null && informationMessage !== ''}>
        <Half>
          <H1Hero>REGISTER AND MANAGE YOUR EPDS ONLINE</H1Hero>
          <CheckBulletList>
            <li>Present transparent, verified and comparable information.</li>
            <li>Life-cycle environmental impact of products.</li>
            <li>Global system for all products and services.</li>
          </CheckBulletList>
          <Text style={{ marginTop: '2rem' }}>
            The EPD Portal guides you step-by-step through the EPD development process. Registration is free of charge. Find
            more information about EPDs on <A href="https://www.environdec.com">www.environdec.com</A>.
          </Text>
          <br />
          <ButtonLinkSmall to={'/register/user'} style={{ display: 'inline-block', marginRight: '2rem' }}>
            Register new account
          </ButtonLinkSmall>
          <A style={{ display: 'inline-block', color: 'gray' }} href={EpdRoutes.account.forgotPassword}>
            Forgot password?
          </A>
        </Half>
        <Half>
          <div>
            <H2>Login</H2>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => login(values)}>
              {({ isSubmitting }) => (
                <StyledForm>
                  <TextInput label="Email Address" name="userName" type="userName" placeholder="joe@epd.com" />
                  <PasswordInput label="Password" name="password" placeholder="Password" autoComplete="new-password" />
                  <Button type="submit" disabled={isSubmitting} style={{ marginTop: '1rem' }}>
                    {isSubmitting ? 'Logging in...' : 'Log in'}
                  </Button>
                  {serverError && <ErrorText>{serverError.toString()}</ErrorText>}
                </StyledForm>
              )}
            </Formik>
          </div>
        </Half>
      </LoginContainer>
    </>
  );
};

const LoginContainer = styled(Container)<{ noTopPadding: boolean }>`
  flex-direction: column-reverse;

  ${(props) => props.theme.media.desktop} {
    flex-direction: row;
  }
  ${(props) =>
    props.noTopPadding &&
    css`
      margin-top: 0;
      margin-bottom: 2rem;
      padding-top: 0;
    `}
`;

const WarningContainer = styled(Container)`
  margin-bottom: 0;
  padding-bottom: 0;
`;

export default Login;
