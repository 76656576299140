import { IconButton } from 'components/v2/buttons';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardAddButton from 'components/v2/epd-wizard-inputs/WizardAddButton';
import { HelpBox } from 'components/v2/help-boxes';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import { Checkbox } from 'primereact/checkbox';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateEpdDataSource, useDeleteEpdDataSource, useUpdateEpdDataSource } from 'services/api/mutations';
import { useEpdDataSourceOptions, useEpdDataSources } from 'services/api/queries';
import styled from 'styled-components';
import { FieldPanel, FieldPanelHeader, FieldPanelInput } from 'styles/v2/Styles.styled';
import { DataSourceOptionModel, EPDDataSourceModel, Option } from 'types/types';

import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';

const DataSourceSection: React.FunctionComponent<{
  epdVersionId: string;
  errors?: any;
}> = ({ epdVersionId, errors }) => {
  const { t } = useTranslation();
  const dataSources = useEpdDataSources(epdVersionId!).data;
  const dataSourceOptionsSource = useEpdDataSourceOptions().data;
  const createDataSource = useCreateEpdDataSource(epdVersionId);
  const updateDataSource = useUpdateEpdDataSource(epdVersionId);
  const deleteDataSource = useDeleteEpdDataSource(epdVersionId);
  const isReadOnly = useIsReadOnlyMode();

  const dataSourceErrors = (index: number) => {
    return errors?.[`epdDataSource[${index}]`];
  };

  const dataSourceOptions = dataSourceOptionsSource?.map((x: DataSourceOptionModel) => {
    return {
      value: x.id,
      label: x.name,
    } as Option;
  });

  const getDataSourceVersionOptions = (dataSourceId?: string) => {
    if (!dataSourceId) {
      return [] as Option[];
    }

    const dataSource = dataSourceOptionsSource?.find(
      (x: DataSourceOptionModel) => x.id.toLowerCase() === dataSourceId.toLowerCase()
    );

    return dataSource?.dataSourceVersions;
  };

  const onChangeDataSource = async (id: string | undefined, propertyName: string, val: any) => {
    let newData;
    if (id) {
      const existingItem = dataSources?.find((x) => x.id === id);
      newData = { ...existingItem, [propertyName]: val } as EPDDataSourceModel;
      if (propertyName === 'dataSourceId') {
        newData.dataSourceVersionId = undefined;
      }
      updateDataSource.mutate(newData);
    } else {
      newData = { epdVersionId: epdVersionId, [propertyName]: val } as EPDDataSourceModel;
      createDataSource.mutate(newData);
    }
  };

  const handleAddNewDataSource = () => {
    const newData = { epdVersionId: epdVersionId } as EPDDataSourceModel;
    createDataSource.mutate(newData);
  };

  const handleDeleteDataSource = (id: string) => {
    deleteDataSource.mutate(id);
  };

  return (
    <FieldPanel>
      <FieldPanelHeader className="w-full">
        {t(`epdCompiler.step3.lcaSpecificationTab.properties.dataSource.title`)}{' '}
        <TooltipHelpIcon content={t(`epdCompiler.step3.lcaSpecificationTab.properties.dataSource.tooltip`)} />
      </FieldPanelHeader>
      {dataSources && dataSources.length > 0 ? (
        dataSources.map((item: EPDDataSourceModel, index: number) => {
          const dataSource = dataSourceOptionsSource?.find(
            (x: DataSourceOptionModel) => x.id.toLowerCase() === item.dataSourceId?.toLowerCase()
          );

          const dataSourceVersionOptions = getDataSourceVersionOptions(item.dataSourceId);
          const dataSourceVersion = dataSourceVersionOptions?.find(
            (x: Option) => x.value.toLowerCase() === item?.dataSourceVersionId?.toLowerCase()
          );
          return (
            <React.Fragment key={index}>
              <FieldPanelInput>
                <CompilerSelectControlledComponent
                  label={t(`epdCompiler.step3.lcaSpecificationTab.properties.dataSource.dataSource`)}
                  name="dataSourceId"
                  options={dataSourceOptions}
                  required
                  value={dataSource ? ({ value: dataSource.id, label: dataSource.name } as Option) : null}
                  onChanged={(propertyName: string, val: any) => {
                    onChangeDataSource(item.id, propertyName, val);
                  }}
                  placeholder="Select"
                  isClearable={false}
                  error={dataSourceErrors(index)?.database}
                />
                <CheckBoxGroup>
                  <Checkbox
                    inputId="isMain"
                    value="isMain"
                    onChange={(event) => {
                      onChangeDataSource(item.id, 'isMain', event.checked);
                    }}
                    checked={item.isMain}
                    disabled={isReadOnly}
                  />
                  <label htmlFor="isMain">{t(`epdCompiler.step3.lcaSpecificationTab.properties.dataSource.isMain`)}</label>
                </CheckBoxGroup>
              </FieldPanelInput>
              <SelectWithIconPanel>
                <CompilerSelectControlledComponent
                  label={t(`epdCompiler.step3.lcaSpecificationTab.properties.dataSource.dataSourceVersion`)}
                  name="dataSourceVersionId"
                  options={dataSourceVersionOptions}
                  required
                  value={dataSourceVersion ?? null}
                  onChanged={(propertyName: string, val: any) => {
                    onChangeDataSource(item.id, propertyName, val);
                  }}
                  placeholder="Select"
                  isClearable={false}
                  error={dataSourceErrors(index)?.databaseVersion}
                />
                {dataSources.length > 1 && !item.isMain && (
                  <DeleteDataSourceIconButton
                    icon="pi pi-trash"
                    text
                    onClick={() => handleDeleteDataSource(item.id)}
                    disabled={isReadOnly}
                  />
                )}
              </SelectWithIconPanel>
            </React.Fragment>
          );
        })
      ) : (
        <>
          <FieldPanelInput>
            <CompilerSelectControlledComponent
              label={t(`epdCompiler.step3.lcaSpecificationTab.properties.dataSource.dataSource`)}
              name="dataSourceId"
              options={dataSourceOptions}
              required
              onChanged={(propertyName: string, val: any) => {
                onChangeDataSource(undefined, propertyName, val);
              }}
              placeholder="Select"
              isClearable={false}
            />
            <CheckBoxGroup>
              <Checkbox
                inputId="isMain"
                value="isMain"
                onChange={(event) => {
                  onChangeDataSource(undefined, 'isMain', event.checked);
                }}
                checked={false}
              />
              <label htmlFor="isMain">{t(`epdCompiler.step3.lcaSpecificationTab.properties.dataSource.isMain`)}</label>
            </CheckBoxGroup>
          </FieldPanelInput>
          <CompilerSelectControlledComponent
            label={t(`epdCompiler.step3.lcaSpecificationTab.properties.dataSource.dataSourceVersion`)}
            name="dataSourceVersionId"
            options={[]}
            required
            onChanged={(propertyName: string, val: any) => {
              onChangeDataSource(undefined, propertyName, val);
            }}
            placeholder="Select"
            isClearable={false}
            containerStyle={{ alignSelf: 'flex-start' }}
          />
        </>
      )}
      <div className="w-full">
        <HelpBox>
          <span>{t(`epdCompiler.step3.lcaSpecificationTab.properties.dataSource.helpBoxNewField`)}</span>
        </HelpBox>
        <WizardAddButton
          label={t('epdCompiler.step3.lcaSpecificationTab.properties.dataSource.addNew')}
          onClick={handleAddNewDataSource}
        />
      </div>
    </FieldPanel>
  );
};

const CheckBoxGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  & .p-checkbox {
    width: 14px;
    height: 14px;
  }
  & .p-checkbox .p-checkbox-box {
    width: 14px;
    height: 14px;
    border: 1px solid ${(props) => props.theme.colors.elementsButtonDisabled};
    border-radius: 0;
  }
  & .p-checkbox .p-checkbox-box.p-highlight {
    background: ${(props) => props.theme.colors.primaryOrange};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  & .p-checkbox .p-checkbox-box.p-highlight:hover {
    background: ${(props) => props.theme.colors.primaryOrange};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  && .p-checkbox-box.p-focus {
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }

  label {
    display: flex;
    margin-left: 0.5rem;
    ${(props) => props.theme.fonts.textSmall}
    color: ${(props) => props.theme.colors.primaryBlack};
  }
`;

const SelectWithIconPanel = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
`;

const DeleteDataSourceIconButton = styled(IconButton)`
  width: 16px !important;
  height: 16px !important;
  align-self: end;
  margin-left: 15px;
  margin-bottom: 15px;

  &.p-button.p-button-text {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  &.p-button.p-button-text:hover {
    background: transparent;
  }
  &.p-disabled {
    opacity: 0.6;
  }
`;

export default DataSourceSection;
