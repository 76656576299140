import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import expandIcon from 'images/icons/svg/expand.svg';
import { Button } from 'primereact/button';
import React from 'react';
import styled from 'styled-components';
import { HeaderCaptionSemiBold, TitleIconBar } from 'styles/v2/Styles.styled';

import useIsReadOnlyMode from '../hooks/useIsReadOnlyMode';
import { GridButton } from './StyledGrid';

type Props = {
  caption: string | undefined;
  onMaximize?: React.MouseEventHandler<HTMLButtonElement>;
  onAddRow: React.MouseEventHandler<HTMLButtonElement>;
  onDeleteRows: React.MouseEventHandler<HTMLButtonElement>;
  deleteRowsDisabled?: boolean;
  error?: any;
  required?: boolean;
  hasValue?: boolean;
  hideMutationButtons?: boolean;
};

const DynamicGridHeader: React.FC<Props> = ({
  caption,
  hasValue,
  onMaximize,
  onAddRow,
  onDeleteRows,
  deleteRowsDisabled,
  error,
  required,
  hideMutationButtons,
}) => {
  const isReadOnly = useIsReadOnlyMode();
  return (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <TitleIconBar>
        <HeaderCaptionSemiBold className="white-space-nowrap">{caption}</HeaderCaptionSemiBold>
        {(error || (required && !hasValue)) && <TooltipErrorIcon content={error || 'Required'} />}
      </TitleIconBar>
      <span>
        {!!onMaximize && (
          <>
            <ExpandButton title="Expand" onClick={onMaximize}>
              <img src={expandIcon} alt="expand" style={{ width: 22, height: 22 }} />
            </ExpandButton>{' '}
          </>
        )}
        {!hideMutationButtons && (
          <>
            <GridButton title="Add" icon="pi pi-plus" onClick={onAddRow} disabled={isReadOnly} />{' '}
            <GridButton
              title="Delete"
              icon="pi pi-trash"
              onClick={onDeleteRows}
              disabled={isReadOnly || deleteRowsDisabled}
            />
          </>
        )}
      </span>
    </div>
  );
};

export default DynamicGridHeader;

const ExpandButton = styled(Button)<{ disabled?: boolean }>`
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-color: ${(props) => props.theme.colors.primaryGreen} !important;
  background-color: ${(props) => props.theme.colors.primaryGreen} !important;
`;
