import 'primeflex/primeflex.css';

import deleteImageIcon from 'assets/images/icons/delete.svg';
import inputIconInfo from 'assets/images/icons/inputs/info.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/small-button/edit.svg';
import { ReactComponent as ExpandIcon } from 'assets/images/icons/small-button/expand.svg';
import { ReactComponent as UploadIcon } from 'assets/images/icons/small-button/upload.svg';
import stepStatusAttention from 'assets/images/icons/status/step/attention.svg';
import stepStatusDeclined from 'assets/images/icons/status/step/declined.svg';
import stepStatusFilled from 'assets/images/icons/status/step/filled.svg';
import stepStatusPending from 'assets/images/icons/status/step/pending.svg';
import styled from 'styled-components';

export const StatusCheckIcon = ({ style }: any) => {
  const defaultStyle = {
    color: '#FFFFFF',
    backgroundColor: '#02C038',
  };
  return <StatusIcon className="pi pi-check" style={{ ...defaultStyle, ...style }} />;
};

export const StatusAttentionIcon = ({ style }: any) => {
  return <img src={stepStatusAttention} style={{ ...style }} />;
};

export const ButtonUploadIcon = ({ style }: any) => {
  return <UploadIcon style={{ marginRight: '0.25rem', ...style }} />;
};

export const EditDataIcon = ({ style }: any) => {
  return <EditIcon style={{ marginRight: '0.25rem', ...style }} />;
};

export const ExpandPanelIcon = ({ style }: any) => {
  return <ExpandIcon style={{ marginRight: '0.25rem', ...style }} />;
};

export const StatusFilledIcon = ({ style }: any) => {
  return <img src={stepStatusFilled} style={{ marginLeft: '4px', ...style }} />;
};

export const StatusInformationIcon = ({ style }: any) => {
  const defaultStyle = {
    color: '#FFFFFF',
    backgroundColor: '#F5DD00',
    marginLeft: '4px',
  };
  return <StatusIcon className="pi pi-info" style={{ ...defaultStyle, ...style }} />;
};

export const StatusDeclinedIcon = ({ style }: any) => {
  return <img src={stepStatusDeclined} style={{ marginLeft: '4px', ...style }} />;
};

export const StatusPendingIcon = ({ style }: any) => {
  return <img src={stepStatusPending} style={{ marginLeft: '4px', ...style }} />;
};

export const StatusApprovedIcon = ({ style }: any) => {
  return <img src={stepStatusFilled} style={{ marginLeft: '4px', ...style }} />;
};

export const StatusHelpIcon = ({ style }: any) => {
  return <img src={inputIconInfo} style={{ ...style }} />;
};

export const DeleteImageIcon = ({ style }: any) => {
  return <img src={deleteImageIcon} alt="x" style={{ ...style }} />;
};

export const InfoHelpIcon = ({ style }: any) => {
  const defaultStyle = { fontSize: '1rem' };
  return <StatusIcon className="pi pi-info-circle" style={{ ...defaultStyle, ...style }} />;
};

export const RegisteredIcon = () => {
  return (
    <IconBox
      className="pi pi-file"
      style={{
        backgroundColor: '#FEF8E6',
        color: '#E79C5B',
      }}
    >
      <InnerIcon className="pi pi-list" style={{ color: '#E79C5B' }} />
    </IconBox>
  );
};

export const PublishedIcon = () => {
  return (
    <IconBox
      className="pi pi-file"
      style={{
        backgroundColor: '#E5F8F1',
        color: '#4A9559',
      }}
    >
      <InnerIcon className="pi pi-globe" style={{ color: '#4A9559' }} />
    </IconBox>
  );
};

export const ReservedIcon = () => {
  return (
    <IconBox
      className="pi pi-file"
      style={{
        backgroundColor: '#E5F8F1',
        color: '#4A9559',
      }}
    >
      <InnerIcon className="pi pi-check" style={{ color: '#4A9559' }} />
    </IconBox>
  );
};

export const DeregisteredIcon = () => {
  return (
    <IconBox
      className="pi pi-file"
      style={{
        backgroundColor: '#FCF4F7',
        color: '#DA605B',
      }}
    >
      <InnerIcon className="pi pi-times" style={{ color: '#DA605B' }} />
    </IconBox>
  );
};

export const InvoiceIcon = () => {
  return (
    <IconBox
      className="pi pi-dollar"
      style={{
        backgroundColor: '#EBECF4',
        color: '#000000',
      }}
    />
  );
};

export const DeleteIcon = () => {
  return (
    <PopupIconBox
      className="pi pi-trash"
      style={{
        backgroundColor: '#FCF4F7',
        color: '#DA605B',
      }}
    />
  );
};

export const AlertIcon = () => {
  return (
    <PopupIconBox
      className="pi pi-exclamation-circle"
      style={{
        backgroundColor: '#F6E5E4',
        color: '#CC302B',
      }}
    />
  );
};

export const CheckIcon = () => {
  return (
    <PopupIconBox
      className="pi pi-check-circle"
      style={{
        backgroundColor: '#E5F8F1',
        color: '#4A9559',
      }}
    />
  );
};

export const InfoIcon = () => {
  return (
    <PopupIconBox
      className="pi pi-info-circle"
      style={{
        backgroundColor: '#EBECF4',
        color: '#7F8081',
      }}
    />
  );
};

export const QuestionIcon = () => {
  return (
    <PopupIconBox
      className="pi pi-question-circle"
      style={{
        backgroundColor: '#FBEBBA',
        color: '#864A14',
      }}
    />
  );
};

const IconBox = styled.i`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fcf4f7;
  font-size: 2rem;
  color: #da605b;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: auto;
  margin-left: auto;
`;

const PopupIconBox = styled(IconBox)`
  width: 70px;
  height: 70px;
`;

const InnerIcon = styled.i`
  position: absolute;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  font-weight: bold;
`;

const StatusIcon = styled.i`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 0.5rem;
  aspect-ratio: 1;
`;

export const AddImageIcon = () => {
  return <ButtonIconBox className="pi pi-plus" />;
};

const ButtonSmallIconBox = styled.i`
  padding: 0 0 0 1px;
  width: 12px;
  height: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primaryOrange};
  font-size: 9px;
  color: ${(props) => props.theme.colors.primaryWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
`;

const ButtonIconBox = styled.i`
  padding: 0 0 0 1px;
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primaryGreen};
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primaryWhite};

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  align-self: center;
  justify-self: center;
`;
