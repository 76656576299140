import InformIcon from 'components/icons/InformIcon';
import { ProcessStatus } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { AddIcon, FilterIcon, SettingsIcon } from 'images/icons/svg';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import CompanyService from 'services/CompanyService';
import { CompanyModel } from 'services/EpdClient';
import styled from 'styled-components';
import { Container, H2, InputSmallIcon, MainView } from 'styles/Styles.styled';
import Spinner from 'styles/spinner.styled';
import { Chip } from 'primereact/chip';

const CompanySelector = () => {
  const { companyAccountId, company, changeCompany } = useContext(CompanyContext);
  const [filter, setFilter] = useState('');
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<CompanyModel[]>([]);
  const [canRedirect, setCanRedirect] = useState<boolean>(false);
  const history = useHistory();
  type Props = React.PropsWithChildren<{
    heading?: string;
    icon: any;
    content: string;
  }>;

  function SettingsCog({ icon, content }: Props) {
    return (
      <CogContainer>
        <Image alt="cog" src={icon} />
        {content && <InformIcon content={content} left />}
        <span>{t('settings')}</span>
      </CogContainer>
    );
  }
  const changeCompanyFeatch = (id?: string) => {
    changeCompany?.(id);
    setCanRedirect(true);
  };
  useEffect(() => {
    if (canRedirect) {
      history.push(EpdLinks.dashboard());
    }
  }, [companyAccountId]);

  useEffect(() => {
    const fetchCompanies = async () => {
      setStatus(ProcessStatus.Fetching);
      const companiesResult = await CompanyService.getCompanies();
      setCompanies(companiesResult);
      setStatus(ProcessStatus.Success);
    };

    fetchCompanies();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const data = companies?.filter((c) => c.name?.toLocaleLowerCase().includes(filter.toLowerCase()));

  const Row = ({ index, style }: ListChildComponentProps) => (
    <div key={data[index].id} style={style}>
      <ChangeOrgRow key={data[index].id} style={{ minWidth: '300px' }}>
        <ChangeOrg
          to=""
          onClick={(e) => {
            e.preventDefault();
            changeCompanyFeatch(data[index].id);
          }}
        >
          {data[index].name}
        </ChangeOrg>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {data[index].roles?.map(role => (
            <div key={role} style={{ paddingRight: '.25rem', paddingTop: '1rem'}}>
              <Chip label={role}/>
            </div>
          ))}
        </div>
        {data[index] && data[index].settingsEnabled && (
          <Link
            to={EpdRoutes.param(EpdRoutes.manageCompany, ':companyid', data[index].id || '')}
            style={{ padding: '1rem' }}
            onClick={() => changeCompany && changeCompany(data[index].id)}
          >
            <SettingsCog icon={SettingsIcon} content={t('epdDashboard.tooltips.editSettings')} />
          </Link>
        )}
      </ChangeOrgRow>
    </div>
  );

  return (
    <Container>
      {status === ProcessStatus.Fetching && <Spinner />}
      <MainView>
        <CurrentCompanyContainer>
          <H2>{company?.name}</H2>
          {status === ProcessStatus.Success && (company?.settingsEnabled ?? false) && (
            <Link
              to={EpdRoutes.param(EpdRoutes.manageCompany, ':companyid', company?.id || '')}
              style={{ padding: '1rem', alignSelf: 'center' }}
            >
              <SettingsCog icon={SettingsIcon} content={t('epdDashboard.tooltips.editSettings')} />
            </Link>
          )}
        </CurrentCompanyContainer>
        <HeaderContainer>
          <h3>{t('epdDashboard.organizations.title')}</h3>
          <div>
            <InputSmallIcon
              style={{ backgroundImage: `url(${FilterIcon})` }}
              type="text"
              placeholder={t('epdDashboard.filter.title')}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </HeaderContainer>
        <div style={{ height: '50vh' }}>
          <AutoSizer>
            {({ height, width }: any) => (
              <List width={width} height={height} itemCount={data.length} itemSize={50}>
                {Row}
              </List>
            )}
          </AutoSizer>
        </div>{' '}
        <CreateNewOrgContainer to={EpdRoutes.account.register.company}>
          <DropDownIcon src={AddIcon} style={{ paddingRight: '.25rem' }} alt={'Create'} />{' '}
          {t('epdDashboard.organizations.create')}
        </CreateNewOrgContainer>
      </MainView>
    </Container>
  );
};

const Image = styled.img`
  height: 1rem;
  vertical-align: top;
  cursor: pointer;
  &:hover {
    & + * {
      display: initial;
    }
  }
`;

const CogContainer = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  border-radius: 15px;
  padding: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0 0 0 1rem;
  align-self: center;
  background-color: ${(props) => props.theme.colors.gray};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.darkGray};
  }
  > span {
    margin-left: 0.5rem;
    align-self: center;
    color: black;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const CurrentCompanyContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChangeOrgRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChangeOrg = styled(Link)`
  padding: 1rem;
  color: black;
  text-decoration: none;
  flex-grow: 1;
  &:hover {
    color: ${(props) => props.theme.colors.regionColorLight};
  }
`;

const CreateNewOrgContainer = styled(ChangeOrg)`
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-top: 1px solid gainsboro;
`;

const DropDownIcon = styled.img`
  height: 15px;
  cursor: pointer;
`;

export default CompanySelector;
