import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpsertEpdReferenceFlow } from 'services/api/mutations';
import { useEpdReferenceFlow } from 'services/api/queries';
import { FieldPanel, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { DictionaryValue, EPDBasedOnUnitEnum, Option, ReferenceFlowModel } from 'types/types';

const ReferenceFlowSection: React.FunctionComponent<{
  epdVersionId: string;
  unitDataSource: DictionaryValue[];
  setIsConversionFactorLock?: any;
  epdBasedOnUnit?: string;
  errors?: any;
}> = ({ epdVersionId, unitDataSource, epdBasedOnUnit, errors, setIsConversionFactorLock }) => {
  const { t } = useTranslation();
  const referenceFlowData = useEpdReferenceFlow(epdVersionId!).data;
  const upsertEpdReferenceFlow = useUpsertEpdReferenceFlow(epdVersionId);

  const unitOptions = unitDataSource.map((x: DictionaryValue) => {
    return {
      value: x.value,
      label: x.description,
    } as Option;
  });

  const selectedReferenceFlowUnit = unitOptions.find(
    (x: Option) => x.value.toLowerCase() === referenceFlowData?.unitId?.toLowerCase()
  );

  const onChangeReferenceFlow = async (propertyName: string, val: any) => {
    const newData = { ...referenceFlowData, [propertyName]: val } as ReferenceFlowModel;
    upsertEpdReferenceFlow.mutate(newData);
  };

  const isConversionFactorToMassEqualToOne =
    referenceFlowData?.value === 1 &&
    epdBasedOnUnit === '0' &&
    referenceFlowData?.unitId === unitOptions?.find((x) => x.label === 'Mass (kg)')?.value.toLocaleLowerCase();

  useEffect(() => {
    setIsConversionFactorLock(isConversionFactorToMassEqualToOne);
  }, [referenceFlowData]);

  return (
    <FieldPanel>
      <FieldPanelHeader className="w-full">
        {t(
          `epdCompiler.step3.lcaSpecificationTab.properties.referenceFlow.title.${
            epdBasedOnUnit ?? Object.keys(EPDBasedOnUnitEnum).indexOf(EPDBasedOnUnitEnum.DeclaredUnit).toString()
          }`
        )}
        <TooltipHelpIcon
          content={t(
            `epdCompiler.step3.lcaSpecificationTab.properties.referenceFlow.tooltip.${
              epdBasedOnUnit ?? Object.keys(EPDBasedOnUnitEnum).indexOf(EPDBasedOnUnitEnum.DeclaredUnit).toString()
            }`
          )}
        />
      </FieldPanelHeader>
      <WizardTextInputComponent
        containerClassName="w-full"
        tooltip="Example: Cement (CEM II 32.5). Unit: Mass (kg). Value: 1.0"
        label={t(`epdCompiler.step3.lcaSpecificationTab.properties.referenceFlow.name`)}
        required
        value={referenceFlowData?.productFlowName}
        onChanged={onChangeReferenceFlow}
        name="productFlowName"
        maxLength={150}
        error={errors?.productFlowName}
      />
      <CompilerSelectControlledComponent
        label={t(`epdCompiler.step3.lcaSpecificationTab.properties.referenceFlow.unit`)}
        name="unitId"
        options={unitOptions}
        required
        value={selectedReferenceFlowUnit}
        onChanged={onChangeReferenceFlow}
        placeholder="Select"
        isClearable={false}
        error={errors?.productFlowUnit}
      />
      <WizardTextInputComponent
        label={t(`epdCompiler.step3.lcaSpecificationTab.properties.referenceFlow.value`)}
        name="value"
        required
        placeholder="Type number"
        value={referenceFlowData?.value}
        onChanged={onChangeReferenceFlow}
        error={errors?.productFlowValue}
      />
    </FieldPanel>
  );
};

export default ReferenceFlowSection;
