import Checkbox from 'components/form/Checkbox';
import { editAdminSelectStyles } from 'components/form/EditAdminSelectStyles';
import HTMLEditor from 'components/form/HTMLEditor';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import { customToolbarCompany } from 'components/form/customToolbar';
import { Form, Formik } from 'formik';
import removeIcon from 'images/icons/svg/error.svg';
import { FixedLabel } from 'pages/pcrWizard/PcrWizard';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminService from 'services/admin/AdminService';
import { Option } from 'types/types';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import HelpIcon from '../../../components/icons/HelpIcon';
import {
  CompanyModel2,
  CompanySize,
  DeveloperType,
  LinkedCompanyModel,
  RoleModel,
  UpdateCompanyModel2,
  VerifierType,
} from '../../../services/EpdClient';
import {
  AdminBox,
  ButtonSmall,
  Container,
  EditFull,
  H2,
  LabelBox,
  SearchBox,
  StyledReactSelect,
  ValueBox,
} from '../../../styles/Styles.styled';
import CompanySettingsLink from './CompanySettingsLink';
import {
  AddCompanyBox,
  AdminGrid,
  CompaniesListBox,
  FirstCheckboxValue,
  Half,
  ImageRemove,
  SecondCheckboxLabel,
  SecondCheckboxValue,
  TwoColumnsDiv,
} from './style';

const EditCompany: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { companyid } = useParams<{ companyid: string }>();
  const [selectedCompany, setSelectedCompany] = useState<CompanyModel2>();
  const [companySizes, setCompanySizes] = useState<CompanySize[]>([]);
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [countriesDs, setCountriesDs] = useState<Option[]>([]);
  const [linkableCompaniesLookup, setLinkableCompaniesLookup] = useState<LinkedCompanyModel[]>([]);
  const [holdingableCompaniesLookup, setHoldingableCompaniesLookup] = useState<LinkedCompanyModel[]>([]);
  const [isGlobalMarketplaceSelected, setIsGlobalMarketplaceSelected] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    website: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    email: Yup.string().email('Invalid email address').nullable(),
    countries: Yup.array().min(1, 'Required'),
    roles: Yup.array(),
    companySize: Yup.mixed<CompanySize>().notOneOf(Object.values(CompanySize), t('messages.required')),
    verifierType: Yup.mixed<VerifierType>().notOneOf(Object.values(VerifierType), t('messages.required')),
    developerType: Yup.mixed<DeveloperType>().notOneOf(Object.values(DeveloperType), t('messages.required')),
    automaticInvoicing: Yup.boolean(),
    hasToolVerificationType: Yup.boolean(),
    hasEPDProcessCertification: Yup.boolean(),
    archived: Yup.boolean(),
    emailNotifications: Yup.boolean(),
    licenseesExclude: Yup.boolean(),
    adminNotes: Yup.string().nullable(),
  });

  const onSaveChanges = async (rawValues: CompanyModel2 | any) => {
    const values: CompanyModel2 | any = {
      ...rawValues,
      isGlobalMarketplace: isGlobalMarketplaceSelected,
    };

    if (isGlobalMarketplaceSelected) {
      values.countries = [];
    } else {
      if (values?.countries) {
        values.countries = values.countries?.map((res: any) => ({
          id: res.value,
          name: res.label,
        }));
      }
    }

    if (values?.roles) {
      values.roles = values.roles?.map((res1: any) => ({
        id: res1.value,
        name: res1.label,
      }));
    }

    if (values?.companySize) {
      values.companySize = values.companySize?.value;
    }

    if (values?.verifierType) {
      values.verifierType = values.verifierType?.value;
    }

    if (values?.developerType) {
      values.developerType = values.developerType?.value;
    }

    if (selectedCompany != null) {
      const updated = applyUpdates(selectedCompany, values);
      try {
        await AdminService.updateCompany(updated);
        toast.success('Organisational information has been saved.', {
          position: 'top-center',
        });
      } catch (error) {
        toast.error(PoorMansError(error), {
          position: 'top-center',
        });
      }
      setSelectedCompany(await AdminService.getCompany(companyid));
    }
  };

  function applyUpdates(
    selectedCompany: CompanyModel2 | undefined,
    companyUpdates: Partial<CompanyModel2>
  ): UpdateCompanyModel2 {
    return { ...selectedCompany, ...companyUpdates };
  }

  const fetchLinkableCompanies = async () => {
    const linkable = await AdminService.getLinkableCompanies(companyid);
    setLinkableCompaniesLookup(linkable);
    return linkable;
  };

  const fetchHoldingableCompanies = async () => {
    const result = await AdminService.getHoldingableCompanies(companyid);
    setHoldingableCompaniesLookup(result);
    return result;
  };

  const fetchCompany = async () => {
    const company = await AdminService.getCompany(companyid);
    setIsGlobalMarketplaceSelected(company.isGlobalMarketplace);
    setSelectedCompany(company as any);

    return company;
  };

  useEffect(() => {
    const fetchRoles = async () => {
      const newRoles = await AdminService.getRoles();
      setRoles(newRoles as any);
      return newRoles;
    };

    const fetchAllCountries = async () => {
      const countries = await AdminService.getCountries(undefined);
      setCountriesDs([
        { value: '', label: 'Global' },
        ...countries.map((country) => {
          return { value: country.id, label: country.name } as Option;
        }),
      ]);
    };

    const fetchData = async () => {
      await fetchCompany();
      await fetchRoles();
      await fetchAllCountries();
      fetchLinkableCompanies();
      fetchHoldingableCompanies();
      setCompanySizes(Object.values(CompanySize));
    };

    const promise = fetchData();
    toast.promise(
      promise,
      {
        pending: 'Loading data...',
        error: 'Failed to load data.',
      },
      {
        position: 'top-center',
        toastId: 'loading',
      }
    );
  }, [companyid]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedCompany || !roles || !countriesDs) {
    return <></>;
  }

  const initialCountries = selectedCompany.isGlobalMarketplace
    ? [{ value: undefined, label: 'Global' }]
    : selectedCompany?.countries?.map((country) => {
        return { label: country.name, value: country.id };
      });

  const initialRoles = selectedCompany?.roles?.map((role) => {
    return { label: role.name, value: role.id };
  });

  const initialCompanySize = { label: selectedCompany?.companySize, value: selectedCompany?.companySize };
  const initialVerifierType = { label: selectedCompany?.verifierType, value: selectedCompany?.verifierType };
  const initialDeveloperType = { label: selectedCompany?.developerType, value: selectedCompany?.developerType };

  const initialValues = {
    ...selectedCompany,
    countries: initialCountries,
    roles: initialRoles,
    companySize: initialCompanySize,
    verifierType: initialVerifierType,
    developerType: initialDeveloperType,
    holdingCompany: null,
    newSubsidiary: null,
  };

  const addDauhgterCompany = async (child: Option, values: any, setFieldValue: any) => {
    if (selectedCompany.parentCompany != null) {
      toast.error(t('admin.company.messages.hasParent') as string, {
        position: 'top-center',
      });

      return;
    }

    await AdminService.setHoldingCompany(child.value, companyid);
    fetchLinkableCompanies();
    fetchHoldingableCompanies();
    fetchCompany();
  };

  const setParentCompany = async (parent: Option, values: any, setFieldValue: any) => {
    if (selectedCompany.childCompanies != null && selectedCompany.childCompanies.length > 0) {
      toast.error(t('admin.company.messages.hasSubsidiary') as string, {
        position: 'top-center',
      });

      return;
    }
    await AdminService.setHoldingCompany(companyid || '', parent.value);
    fetchHoldingableCompanies();
    fetchLinkableCompanies();
    fetchCompany();
  };

  const detachDaughterCompany = async (daughterCompanyId: string, values: any, setFieldValue: any) => {
    await AdminService.setHoldingCompany(daughterCompanyId, undefined);
    fetchHoldingableCompanies();
    fetchLinkableCompanies();
    fetchCompany();
  };

  const handleChangeMarketplace = (options: Option[]) => {
    const globalOption = options?.find((option) => option.label === 'Global');
    if (globalOption) {
      setIsGlobalMarketplaceSelected(true);
      return [globalOption];
    } else {
      setIsGlobalMarketplaceSelected(false);
      return options;
    }
  };

  const handleChangeExcludeFromLicensee = async (value: boolean, values: any, setFieldValue: any) => {
    const isCompanyInLicensee = selectedCompany.isCompanyCountryInLicensee && !value;
    const isHoldingCompany =
      selectedCompany.parentCompany || (selectedCompany.childCompanies && selectedCompany.childCompanies.length > 0);
    const isAutomaticInvoicingEnabled = !isCompanyInLicensee && !isHoldingCompany;

    if (!values.automaticInvoicing && isAutomaticInvoicingEnabled) {
      setFieldValue('automaticInvoicing', true);
    }

    if (values.automaticInvoicing && !isAutomaticInvoicingEnabled) {
      setFieldValue('automaticInvoicing', false);
    }
  };

  const handleChangeAutomaticInvoicing = async (value: boolean, setFieldValue: any) => {
    if (!value) {
      setFieldValue('automaticInvoicing', !window.confirm(t('admin.company.messages.confirmAutomaticInvoiceUncheck')));
    }
  };

  return (
    <Container>
      <EditFull>
        <H2>Organisation settings</H2>
        <AdminBox style={{ backgroundColor: '#FDFDFD' }}>
          <Formik
            enableReinitialize
            initialValues={initialValues as any}
            validationSchema={validationSchema}
            onSubmit={onSaveChanges}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <TwoColumnsDiv>
                  <Half>
                    <AdminGrid>
                      <LabelBox>
                        <FixedLabel>
                          Name <HelpIcon content={Tooltips.name} />
                        </FixedLabel>
                      </LabelBox>
                      <ValueBox>
                        <LargeRadiusTextInput name="name" type="text" />
                      </ValueBox>
                      <LabelBox>
                        <FixedLabel>Website</FixedLabel>
                      </LabelBox>
                      <ValueBox>
                        <LargeRadiusTextInput name="website" type="text" />
                      </ValueBox>
                      <LabelBox>
                        <FixedLabel>Phone</FixedLabel>{' '}
                      </LabelBox>
                      <ValueBox>
                        <LargeRadiusTextInput name="phone" type="text" />
                      </ValueBox>
                      <LabelBox>
                        <FixedLabel>Email</FixedLabel>
                      </LabelBox>
                      <ValueBox>
                        <LargeRadiusTextInput name="email" type="email" />
                      </ValueBox>
                      <LabelBox>
                        <FixedLabel>Vat</FixedLabel>{' '}
                      </LabelBox>
                      <ValueBox>
                        <LargeRadiusTextInput name="vat" type="text" />
                      </ValueBox>
                      <LabelBox>
                        <FixedLabel>Company size</FixedLabel>{' '}
                      </LabelBox>
                      <ValueBox>
                        <StyledReactSelect
                          name={'companySize'}
                          defaultValue={initialValues.companySize}
                          styles={editAdminSelectStyles}
                          options={companySizes.map((size) => {
                            return { value: size, label: size } as Option;
                          })}
                        />
                      </ValueBox>
                      <LabelBox>
                        <FixedLabel>Marketplace</FixedLabel>{' '}
                      </LabelBox>
                      <ValueBox>
                        <StyledReactSelect
                          defaultValue={initialValues.countries}
                          name="countries"
                          isMulti
                          styles={editAdminSelectStyles}
                          options={countriesDs}
                          onChange={handleChangeMarketplace}
                          isClearable
                          shouldMenuOpen={!isGlobalMarketplaceSelected}
                        />
                      </ValueBox>
                      <LabelBox>
                        <FixedLabel>Roles</FixedLabel>{' '}
                      </LabelBox>
                      <ValueBox>
                        <StyledReactSelect
                          defaultValue={initialValues.roles}
                          name="roles"
                          isMulti={true}
                          styles={editAdminSelectStyles}
                          options={roles?.map((role) => {
                            return { value: role.id, label: role.name } as Option;
                          })}
                          isClearable={true}
                        />
                      </ValueBox>
                      <LabelBox>
                        <FixedLabel>Verifier type</FixedLabel>{' '}
                      </LabelBox>
                      <ValueBox>
                        <StyledReactSelect
                          defaultValue={initialValues.verifierType}
                          name="verifierType"
                          styles={editAdminSelectStyles}
                          options={[
                            { value: VerifierType.Missing, label: VerifierType.Missing },
                            { value: VerifierType.Body, label: VerifierType.Body },
                            { value: VerifierType.Individual, label: VerifierType.Individual },
                          ]}
                        />
                      </ValueBox>
                      {values.verifierType?.value === VerifierType.Body && (
                        <>
                          <LabelBox>
                            <FixedLabel>Accredited by</FixedLabel>{' '}
                          </LabelBox>
                          <ValueBox>
                            <LargeRadiusTextInput name="accreditedBy" type="text" />
                          </ValueBox>
                        </>
                      )}
                      <LabelBox>
                        <FixedLabel>Developer type</FixedLabel>{' '}
                      </LabelBox>
                      <ValueBox>
                        <StyledReactSelect
                          defaultValue={initialValues.developerType}
                          name="developerType"
                          styles={editAdminSelectStyles}
                          options={[
                            { value: DeveloperType.None, label: DeveloperType.None },
                            { value: DeveloperType.Internal, label: DeveloperType.Internal },
                            { value: DeveloperType.External, label: DeveloperType.External },
                          ]}
                        />
                      </ValueBox>

                      <LabelBox>
                        <FixedLabel>Automatic invoice</FixedLabel>
                      </LabelBox>
                      <FirstCheckboxValue>
                        <Checkbox
                          name="automaticInvoicing"
                          style={{ margin: 0 }}
                          handlePostChangeAction={(event: any) => {
                            handleChangeAutomaticInvoicing(event.target.checked, setFieldValue);
                          }}
                        />
                      </FirstCheckboxValue>
                      <SecondCheckboxLabel>
                        <FixedLabel>Pre-verified & Integrated tool</FixedLabel>
                      </SecondCheckboxLabel>
                      <SecondCheckboxValue>
                        <Checkbox name="hasToolVerificationType" style={{ margin: 0 }} />
                      </SecondCheckboxValue>
                      <LabelBox>
                        <FixedLabel>Archived</FixedLabel>
                      </LabelBox>
                      <FirstCheckboxValue>
                        <Checkbox name="archived" style={{ margin: 0 }} />
                      </FirstCheckboxValue>
                      <SecondCheckboxLabel>
                        <FixedLabel>Has EPD process certification</FixedLabel>
                      </SecondCheckboxLabel>
                      <SecondCheckboxValue>
                        <Checkbox name="hasEPDProcessCertification" style={{ margin: 0 }} />
                      </SecondCheckboxValue>
                      <LabelBox>
                        <FixedLabel>Exclude company from licensee</FixedLabel>
                      </LabelBox>
                      <FirstCheckboxValue>
                        <Checkbox
                          name="licenseesExclude"
                          style={{ margin: 0 }}
                          handlePostChangeAction={(event: any) => {
                            handleChangeExcludeFromLicensee(event.target.checked, values, setFieldValue);
                          }}
                        />
                      </FirstCheckboxValue>
                      <SecondCheckboxLabel>
                        <FixedLabel>Email notifications</FixedLabel>{' '}
                      </SecondCheckboxLabel>
                      <SecondCheckboxValue>
                        <Checkbox name="emailNotifications" style={{ margin: 0 }} />
                      </SecondCheckboxValue>
                      <LabelBox>
                        <FixedLabel>Administration notes</FixedLabel>
                      </LabelBox>
                      <ValueBox>
                        <HTMLEditor name="adminNotes" toolbarHidden={false} toolbar={customToolbarCompany} />
                      </ValueBox>
                      <SearchBox>
                        <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                          Save
                        </ButtonSmall>
                      </SearchBox>
                    </AdminGrid>
                  </Half>
                  <Half>
                    <AdminGrid>
                      <LabelBox>
                        <FixedLabel>{t('admin.company.holding')}:</FixedLabel>
                      </LabelBox>
                      <ValueBox style={{ display: 'flex', alignItems: 'center' }}>
                        {selectedCompany.parentCompany && <CompanySettingsLink company={selectedCompany.parentCompany} />}
                      </ValueBox>
                      <LabelBox>
                        <FixedLabel>{t('admin.company.chooseHolding')}</FixedLabel>
                      </LabelBox>
                      <AddCompanyBox>
                        <StyledReactSelect
                          defaultValue={initialValues.holdingCompany}
                          styles={editAdminSelectStyles}
                          name="holdingCompany"
                          options={holdingableCompaniesLookup?.map((company) => {
                            return { value: company.id, label: company.name } as Option;
                          })}
                          isClearable
                        />
                        <ButtonSmall
                          style={{ padding: '0.5rem 1rem', marginLeft: '1rem', borderRadius: '3px' }}
                          type="button"
                          disabled={values.holdingCompany == null}
                          onClick={() => {
                            setParentCompany(values.holdingCompany, values, setFieldValue);
                            setFieldValue('holdingCompany', initialValues.holdingCompany);
                          }}
                        >
                          Set
                        </ButtonSmall>
                      </AddCompanyBox>
                      <CompaniesListBox key={'adminCompaniesListBox'}>
                        <LabelBox>
                          <FixedLabel>{t('admin.company.subsidiaries')}</FixedLabel>
                        </LabelBox>
                        {selectedCompany.childCompanies?.map((c) => {
                          return (
                            <Fragment key={c.id}>
                              <LabelBox>
                                <CompanySettingsLink company={c} />
                              </LabelBox>
                              <ImageRemove>
                                <img
                                  src={removeIcon}
                                  style={{ height: '1rem' }}
                                  alt="Remove"
                                  onClick={() => {
                                    detachDaughterCompany(c.id || '', values, setFieldValue);
                                  }}
                                />
                              </ImageRemove>
                            </Fragment>
                          );
                        })}
                      </CompaniesListBox>

                      <LabelBox>
                        <FixedLabel>{t('admin.company.addSubsidiaries')}</FixedLabel>
                      </LabelBox>
                      <AddCompanyBox>
                        <StyledReactSelect
                          defaultValue={initialValues.newSubsidiary}
                          name="newSubsidiary"
                          options={linkableCompaniesLookup?.map((company) => {
                            return { value: company.id, label: company.name } as Option;
                          })}
                          isClearable
                        />
                        <ButtonSmall
                          style={{ padding: '0.5rem 1rem', marginLeft: '1rem', borderRadius: '3px' }}
                          type="button"
                          disabled={values.newSubsidiary == null}
                          onClick={() => {
                            addDauhgterCompany(values.newSubsidiary, values, setFieldValue);
                            setFieldValue('newSubsidiary', initialValues.newSubsidiary);
                          }}
                        >
                          +
                        </ButtonSmall>
                      </AddCompanyBox>
                    </AdminGrid>
                  </Half>
                </TwoColumnsDiv>
              </Form>
            )}
          </Formik>
        </AdminBox>
      </EditFull>
    </Container>
  );
};

const Tooltips = {
  name: 'Tooltip',
};

export default EditCompany;
