import { Checkbox } from 'primereact/checkbox';
import styled from 'styled-components';

export const LabelGroup = styled.div`
  ${(props) => props.theme.typography.headerCaptionRegular}
  display: flex;
  align-self: auto;
  align-items: center;
  margin: 0rem 1rem 0.5rem 0;
  position: relative;
  gap: 4px;
`;

export const StyledPrimeCheckbox = styled(Checkbox)<{ checked: boolean }>`
  margin-right: 0.5rem;
  && .p-checkbox-box {
    background-color: ${(props) => (props.checked ? props.theme.colors.primaryOrange : props.theme.colors.primaryWhite)};
    border-color: ${(props) => (props.checked ? props.theme.colors.primaryOrange : props.theme.colors.statusLabelGrayText)};
  }
  && .p-checkbox-box.p-highlight:hover,
  &&.p-checkbox.p-checkbox-focused .p-checkbox-box.p-focus,
  .p-checkbox-icon:hover {
    background-color: ${(props) => (props.checked ? props.theme.colors.lightOrange : props.theme.colors.primaryWhite)};
    border-color: ${(props) => (props.checked ? props.theme.colors.lightOrange : props.theme.colors.primaryBlack50)};
  }
`;

export const FindButtonBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: none;
  justify-content: start;
  display: flex;
  max-height: 70px;

  .p-button.p-button-text {
    padding: 0;
    background-color: transparent;
    border: none;
    margin-top: 0.25rem;
    ${(props) => props.theme.fonts.standard};
  }
  .p-button.p-button-text:hover {
    background-color: transparent;
  }
  .p-button.p-button-text:disabled {
    color: #757575;
  }
`;
