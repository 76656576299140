import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { useDictionaryValues } from 'services/api/queries';
import {
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanel,
  HeaderCaptionSemiBold,
} from 'styles/v2/Styles.styled';
import { EPDProductInformationModel, Option } from 'types/types';
import { getOptionsFromDictionary } from 'util/utils';

import ToggleButton from '../../panels/toggleable/ToggleButton';

const LifespanSection: React.FunctionComponent<{
  product?: EPDProductInformationModel;
  onChange: any;
}> = ({ product, onChange }) => {
  const dictionariesQuery = useDictionaryValues();

  const unitTypeOptions = getOptionsFromDictionary(dictionariesQuery.data, EPD_DICTIONARY_CODES.UNIT_TYPE);

  const selectedUnitTypeOriginal = !isNaN(Number(product?.unitType))
    ? unitTypeOptions.find((x: any) => x.value == product?.unitType)
    : unitTypeOptions.find((x: any) => x.label == product?.unitType);

  const selectedProductType = selectedUnitTypeOriginal
    ? ({
        value: selectedUnitTypeOriginal?.value.toString(),
        label: selectedUnitTypeOriginal?.label,
      } as Option)
    : null;

  const headerModuleTemplate = (
    options: any,
    header: string,
    tooltip?: string,
    tooltipPosition?: any | undefined,
    required?: boolean,
    isFilledIn?: boolean
  ) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {required && !isFilledIn && <TooltipErrorIcon />}
          <ToggleButton options={options} />
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  return (
    <ExpandablePanelModule
      headerTemplate={(options) =>
        headerModuleTemplate(
          options,
          'Actual or technical lifespan',
          'The average time for which the product has been shown to be in use. Lifespans shall be expressed in relevant units such as years, operating hours, or kilometres travelled.',
          'top | right',
          false,
          false
        )
      }
      toggleable
    >
      <FieldPanel style={{ paddingTop: '0', marginTop: '0' }}>
        <CompilerSelectControlledComponent
          label="Unit"
          name="unit"
          options={unitTypeOptions}
          value={selectedProductType}
          onChanged={onChange}
          placeholder="Select"
        />
        <WizardTextInputComponent
          label="Value"
          placeholder="Type here"
          value={product?.unitValue}
          onChanged={onChange}
          name="unitValue"
        />
      </FieldPanel>
    </ExpandablePanelModule>
  );
};

export default LifespanSection;
