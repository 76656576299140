import { TextButton } from 'components/v2/buttons';
import { ExpandPanelIcon } from 'components/v2/icons';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import React, { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { TitleIconBar } from 'styles/v2/Styles.styled';

import { PanelTitle } from '../Styles.styled';
import MaximizedDialog from './MaximizedDialog';

type TProps = React.PropsWithChildren<{
  title: string;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  error?: any;
  style?: CSSProperties;
}>;
const GridPanel = ({ title, tooltip, tooltipPosition, error, style, children }: TProps) => {
  const [visible, setVisible] = useState(false);
  const onMaximize = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: 'grid', ...style }}>
      <MaximizedDialog title={title} onHide={() => setVisible(false)} visible={visible}>
        {children}
      </MaximizedDialog>

      <ElementsContainer>
        <TitleIconBar>
          <PanelTitle>{title}</PanelTitle>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {error && <TooltipErrorIcon content={error || 'Required'} />}
        </TitleIconBar>

        <TextButton
          className="white-space-nowrap"
          style={{ cursor: 'pointer' }}
          label="Expand table"
          icon={ExpandPanelIcon}
          text
          onClick={onMaximize}
        />
      </ElementsContainer>
      <div style={{ overflowX: 'hidden' }}>{!visible && children}</div>
    </div>
  );
};

const ElementsContainer = styled.div`
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 38px;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
`;

export default GridPanel;
