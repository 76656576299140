import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/theme.css';
import 'util/extensions';

import './App.css';

import StartupDialogsComponent from 'components/dialogs/StartupDialogsComponent';
import FooterComponent from 'components/footer/FooterComponent';
import HeaderComponent from 'components/header/HeaderComponent';
import ApplicationLoadingSpinner from 'components/spinners/ApplicationLoadingSpinner';
import { ApplicationToastRoot } from 'components/v2/toast';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { useTheme } from 'hooks/useTheme';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import NewUIRoutes from 'routes/NewUiRoutes';
import OldUIRoutes from 'routes/OldUiRoutes';
import { ThemeProvider } from 'styled-components';
import { ApplicationContentBody, ApplicationModalRoot } from 'styles/Styles.styled';
import queryClient from 'util/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

function App() {
  const [hasLoggedInBefore, setHasLoggedInBefore] = useState<boolean>(false);
  const [isLoadingState, setIsLoadingState] = useState<boolean>(true);
  const location = useLocation();
  const isPdfBuilderUrl =
    location &&
    (location.pathname.toLowerCase().includes('/epdcompilerpdfpreview') ||
      location.pathname.toLowerCase().includes('/epdcompilerdocumentpreview'));

  const isFromOurDomain =
    process.env.REACT_APP_DEBUG ||
    document.referrer.toLowerCase().includes('localhost') ||
    document.referrer.toLowerCase().includes('epd-portal-api') ||
    document.referrer.toLowerCase().includes('epd-portal-app') ||
    document.referrer.toLowerCase().includes('environdec.com');

  const { refreshCompany, licensee } = useContext(CompanyContext);
  const { theme, changeTheme, isNewUI, onChangeUI } = useTheme(licensee);

  useEffect(() => {
    if (isPdfBuilderUrl && isFromOurDomain) {
      return;
    }
    refreshCompany();
    setIsLoadingState(false);
    setHasLoggedInBefore(!!localStorage.getItem('loggedInBefore'));
  }, []);

  if (isPdfBuilderUrl && isFromOurDomain) {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <NewUIRoutes hasLoggedInBefore={false} />
        </ThemeProvider>
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {isLoadingState ? (
          <ApplicationLoadingSpinner />
        ) : (
          <Router>
            <HeaderComponent isNewUI={isNewUI} onChangeUI={onChangeUI} changeTheme={changeTheme} />

            <ApplicationContentBody>
              {isNewUI ? <NewUIRoutes hasLoggedInBefore={hasLoggedInBefore} /> : <OldUIRoutes changeTheme={changeTheme} />}
            </ApplicationContentBody>

            <FooterComponent isNewUI={isNewUI} />

            <ApplicationModalRoot id="modal" />
            <ApplicationToastRoot />
            <StartupDialogsComponent />
          </Router>
        )}
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
